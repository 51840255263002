import { getPrefixedUrl } from "@services/api";

interface Setting {
    width?: number;
    height?: number;
}

const addUrlParameter = (url: string, key: string, value: string | number) => {
    const separator = url.indexOf('?') > -1 ? '&' : '?';

    return `${url}${separator}${key}=${value}`;
};

const createImageUrl = (url: string, { width, height }: Setting, options= {mode: 'crop'}) => {
    if (options.mode !== 'none') {
         url = addUrlParameter(url, 'mode', options.mode);
    }

    if (width) url = addUrlParameter(url, 'width', width);
    if (height) url = addUrlParameter(url, 'height', height);

    return getPrefixedUrl(url);
};

export default createImageUrl;
