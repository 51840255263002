import { h } from '@atomify/jsx';

interface TextareaProps {
    validationTypes?: string | null;
    validationRegex?: string | null;
    validationErrorMessage?: string | null;
    requiredErrorMessage?: string | null;
    value?: any;
    classes?: string;
    disabled?: boolean;
    name: string;
    id: string;
    required?: boolean;
    hook?: string;
    attr?: string[];
    label: string;
    error?: string;
    labelAsPlaceholder?: boolean;
    sronly?: boolean;
    placeholder?: string;
    displayName?: string | null;
    maxLength?: number | null;
    maxLengthErrorMessage?: string | null;
}

export const TextareaElement = ({
    validationTypes,
    validationRegex,
    validationErrorMessage,
    requiredErrorMessage,
    value,
    classes,
    disabled,
    name,
    id,
    required,
    hook,
    attr,
    label,
    error,
    labelAsPlaceholder,
    sronly,
    placeholder,
    displayName,
    maxLength,
    maxLengthErrorMessage,
}: TextareaProps) => {
    const inputClasses = {
        ['c-textarea']: true,
        ['form__item']: true,
        ['form__item--disabled']: disabled,
        ['form__item--lap']: labelAsPlaceholder,
    };
    return (
        <bpd-textarea>
            <bpd-input
                validation-types={validationTypes}
                validation-regex={validationRegex}
                validation-error-message={validationErrorMessage}
                required-error-message={requiredErrorMessage}
                max-length={maxLength}
                max-length-error-message={maxLengthErrorMessage}
                bindValue={value}
                displayName={displayName}
                class={classes}>
                <div class={inputClasses}>
                    <div class="textarea__wrapper">
                        <label
                            class={{ ['textarea__label']: true, ['u-sr-only']: sronly }}
                            for={id}>
                            {label}
                        </label>

                        <textarea
                            class="textarea__input"
                            name={name}
                            id={id}
                            required={required}
                            disabled={disabled}
                            placeholder={labelAsPlaceholder ? null : placeholder}
                            {...{ [`js-hook-${hook ? hook : ''}`]: !!hook, ...attr }}></textarea>
                    </div>
                    <div class="form__item-error" role="alert">
                        {error}
                    </div>
                </div>
            </bpd-input>
        </bpd-textarea>
    );
};
