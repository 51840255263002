import { h } from '@atomify/jsx';

export interface FormProps {
    action?: string;
    method?: string;
    novalidate?: boolean;
    children?: any;
    hidden?: boolean;
    saveWhenComplete?: boolean;
    id?: string;
    type?: string;
}

export const FormRowElement = (props: any) => <div class="form__row">{props.children}</div>;

export const FormElement = (props: FormProps) => (
    <bpd-form
        hidden={props.hidden}
        saveWhenComplete={props.saveWhenComplete}
        type={props.type}
        id={props.id}>
        <form
            class="c-form"
            action={props.action}
            method={props.method}
            novalidate={props.novalidate}>
            {props.children}
        </form>
    </bpd-form>
);

export const FormHeading = ({ heading }: { heading: string }) => (
    <h3 class="form__heading">{heading}</h3>
);
export const FormRichText = ({ html }: { html: string }) => (
    <div class="s-rich-text" dangerouslySetInnerHTML={html} />
);
