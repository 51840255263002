import { Component, Listen, Prop, Query, Watch } from '@atomify/core';

import style from './overlay.scss';

const ARIA_EXPANDED = 'aria-expanded';
const ARIA_HIDDEN = 'aria-hidden';
const HOOK_CLOSE_BUTTON = '[js-hook-close-overlay]';
const HOOK_TOGGLE_OVERLAY = '[js-hook-toggle-overlay]';

@Component({
    tag: 'bpd-overlay',
    style,
})
export class Overlay extends HTMLElement {
    @Query(HOOK_CLOSE_BUTTON) closeOverlay: HTMLElement;
    @Query(HOOK_TOGGLE_OVERLAY) toggleOverlay: HTMLElement;
    @Prop({ reflectToAttribute: true, type: 'Boolean' }) expanded: boolean = false;

    _relatedToggle: HTMLElement | null;

    /**
     * Gets the related button through aria controls.
     * @readonly
     * @memberof Overlay
     */
    get relatedToggle() {
        if (!this._relatedToggle) {
            this._relatedToggle = document.querySelector(`[aria-controls="${this.id}"]`);
        }

        return this._relatedToggle;
    }

    @Watch('expanded')
    visibilityChanged(newValue: boolean) {
        this.setAriaAttributes(newValue);
    }

    @Listen('click', { target: 'closeOverlay' })
    handleClose() {
        this.expanded = !this.expanded;
        this.setAriaAttributes(this.expanded);
    }

    @Listen('click', { target: 'toggleOverlay' })
    handleToggle() {
        this.expanded = !this.expanded;
        this.setAriaAttributes(this.expanded);
    }

    componentDidLoad() {
        if (!this?.getAttribute('aria-hidden')) {
            this.expanded = true;
        }

        this.appendControlEvents();
    }

    /**
     * Append click event listener to related toggle.
     * @memberof Overlay
     */
    private appendControlEvents() {
        this.relatedToggle?.addEventListener('click', () => (this.expanded = !this.expanded));
    }

    /**
     * Adds the correct aria attributes to the related button and the <overlay>.
     * @private
     * @param {boolean} state
     * @memberof Overlay
     */
    private setAriaAttributes(state: boolean) {
        this.relatedToggle?.setAttribute(ARIA_EXPANDED, `${state}`);
        this.setAttribute(ARIA_HIDDEN, `${!state}`);
    }
}
