export const useMapStorage = <T = any, V = any>() => {
    const mapStorage = new Map<T, V>();

    const setItem = (key: T, value: V) => mapStorage.set(key, value);

    const getItem = (key: T) => mapStorage.get(key);

    const hasItem = (key: T) => mapStorage.has(key);

    const deleteItem = (key: T) => mapStorage.delete(key);

    return {
        setItem,
        getItem,
        hasItem,
        deleteItem,
    };
};
