import {
    defineElement,
    FC,
    useElement,
    useElements,
    useListen,
    useProp,
    useRef,
    useWatch,
} from '@atomify/hooks';
import { useClickOutside } from '@source/utilities/hooks';
import { mediaIsMatch, min } from '@source/utilities/media-query';

import { BPDInput } from './input';

const FLOATING_LABEL = 'floating-label-active';
const SELECT_DROPDOWN_ACTIVE = 'select__dropdown--is-active';
const SELECT_ITEM_ACTIVE = 'select__dropdown-button--selected';

export const Select: FC = ({ element }) => {
    const select = useElement<BPDInput>('bpd-input');
    const dropdown = useElement<HTMLSelectElement>('[js-select-dropdown]');
    const selectItems = useElements<HTMLButtonElement[]>('[js-hook-select-item]');
    const [mobileEnabled] = useProp<boolean>('mobileEnabled', false);
    const [disableEvents] = useProp<boolean>('disableEvents', false);

    const dropdownActive = useRef<boolean>(false);
    const currentActiveDropdown = useRef<HTMLButtonElement | null>(null);
    const form = element.closest('form');

    // Close the dropdown when clicking outside of the component
    useClickOutside(element, clickOutside => {
        if (clickOutside) {
            dropdownActive.current = false;
        }
    });

    useListen(select, 'mousedown', openDropdown);
    useListen(selectItems, 'click', setSelectedItem);

    // Watch dropdownactive ref
    useWatch(() => {
        toggleDropdown(dropdownActive.current);
    });

    function openDropdown(e: MouseEvent) {
        if (mobileEnabled || mediaIsMatch(min('tabletLandscape'))) {
            e.preventDefault();
            dropdownActive.current = !dropdownActive.current;
        }
    }

    function toggleDropdown(state: boolean) {
        const action = state ? 'add' : 'remove';
        dropdown.current!.classList[action](SELECT_DROPDOWN_ACTIVE);
        element.classList[action](FLOATING_LABEL);
    }

    function setSelectedItem(e: MouseEvent) {
        e.preventDefault();

        const target = e.currentTarget as HTMLButtonElement;

        if (target) {
            const { value } = target.dataset;

            // Remove active class from current element.
            if (currentActiveDropdown.current) {
                currentActiveDropdown.current.classList.remove(SELECT_ITEM_ACTIVE);
            }

            target.classList.add(SELECT_ITEM_ACTIVE);

            // Set the value of the selectbox
            select.current!.rebindValue(`${value}`);

            currentActiveDropdown.current = target;

            if (!disableEvents) {
                // Manually trigger form change event
                form?.dispatchEvent(new Event('change'));
            }
        }
    }
};

Select.props = {
    mobileEnabled: {
        type: Boolean,
        reflectToAttr: true,
    },
    disableEvents: {
        type: Boolean,
        reflectToAttr: true,
    },
};

defineElement('bpd-select', Select);
