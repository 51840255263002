import QueryString from 'query-string';

import { useHistory } from './use-history';

export const useUrlParams = () => {
    function pushParams(param: string, value: string) {
        const { push } = useHistory();
        const url = window.location.href.split('?');

        const parsed = QueryString.parse(location.search);

        parsed[param] = value;

        const stringified = QueryString.stringify(parsed);
        const pageUrl = `${url[0]}?${stringified}`;

        push(pageUrl);
    }

    function getParams(param: string) {
        const parsed = QueryString.parse(location.search);

        return parsed[param];
    }

    function clearParams() {
        const { push } = useHistory();
        const url = window.location.href.split('?');

        push(url[0]);
    }

    function clearSpecificParam(param: string) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.delete(param);
    }

    return {
        push: pushParams,
        get: getParams,
        clear: clearParams,
        clearSpecificParam,
    };
};
