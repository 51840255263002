import { Component, Query } from '@atomify/core';
import { inviewObserver } from '@utilities/inview';

import createImageUrl from './create-image-url';
import hasResponsiveImages from './detect-responsive-images';
import ObjectFit from './object-fit';

const LAZY_IMAGE_ANIMATE_IN_CLASS = 'image--is-loaded';

const SETTINGS_ATTRIBUTE = 'data-settings';
const BASE_IMAGE_ATTRIBUTE = 'data-image';

export interface Setting {
    width?: number;
    height?: number;
    src?: boolean | undefined;
}

export interface Settings {
    [key: string]: Setting;
}

const SUPPORTS_SRCSET = hasResponsiveImages.srcset || hasResponsiveImages.picture;

@Component({
    tag: 'bpd-image',
})
export class BPDImage extends HTMLElement {
    @Query('img') img: HTMLImageElement;

    componentDidLoad() {
        inviewObserver.observe(this, inView => inView && this.loadImage());
    }

    disconnectedCallback() {
        inviewObserver.unobserve(this);
    }

    private parseSettings(json: string) {
        return JSON.parse(json) || {};
    }

    private generateSrc(baseImage: string, settings: Settings) {
        const keys = Object.keys(settings);

        // Set setting to first one by default
        let setting = settings[keys[0]];

        // Overwrite when a setting is found with scr: true
        if (keys.length > 1) {
            const srcKey = keys.find(key => !!settings[key].src);

            setting = srcKey ? settings[srcKey] : ({} as Setting);
        }

        const src = createImageUrl(baseImage, setting);

        return src;
    }

    private generateSrcset(baseImage: string, settings: Settings) {
        const keys = Object.keys(settings);

        const srcset = keys.reduce((srcset, key, index) => {
            const setting = settings[key];
            const url = createImageUrl(baseImage, setting);
            const isLast = index + 1 === keys.length;

            return `${srcset} ${url} ${key}${isLast ? '' : ','}`;
        }, '');

        return srcset;
    }

    private loadImage() {
        const image = this.img;

        if (!image) return;

        inviewObserver.unobserve(this);

        const baseImage = image.getAttribute(BASE_IMAGE_ATTRIBUTE) as string;
        const settingsAttribute = image.getAttribute(SETTINGS_ATTRIBUTE);

        if (settingsAttribute) {
            const settings = this.parseSettings(settingsAttribute);

            const src = this.generateSrc(baseImage, settings);
            const srcset = this.generateSrcset(baseImage, settings);

            // // If there is no data-src set just render the element.
            if (!src) {
                this.renderImage(this);
                return;
            }

            if (SUPPORTS_SRCSET && srcset) {
                image.srcset = srcset;
            }

            image.src = src;
            image.onload = () => this.renderImage(this);
        } else {
            this.renderImage(this);
        }
    }

    private renderImage(element: HTMLElement) {
        const image = this.img;

        element.classList.add(LAZY_IMAGE_ANIMATE_IN_CLASS);
        image.removeAttribute(SETTINGS_ATTRIBUTE);
        image.removeAttribute(BASE_IMAGE_ATTRIBUTE);

        if (!hasResponsiveImages.currentSrc) {
            (image as any).currentSrc = `${image.src}`;
        }

        ObjectFit.polyfillObjectFit(image);
    }
}
