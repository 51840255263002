import createStore from '@utilities/store';

interface State {
    modals: {
        [key: string]: {
            active: boolean;
        };
    };
}

const ADD_MODAL = 'addModal';
const OPEN_MODAL = 'openModal';
const CLOSE_MODAL = 'closeModal';
const CLOSE_ALL_MODALS = 'closeAllModals';

const initialState: State = {
    modals: {},
};

const actions = {
    [ADD_MODAL]: (state: State, payload: { id: string }) => {
        state.modals = {
            ...state.modals,
            [payload.id]: {
                active: false,
            },
        };

        return state;
    },
    [OPEN_MODAL]: (state: State, payload: { id: string }) => {
        state.modals = {
            ...state.modals,
            [payload.id]: {
                active: true,
            },
        };
        return state;
    },
    [CLOSE_MODAL]: (state: State, payload: { id: string }) => {
        state.modals = {
            ...state.modals,
            [payload.id]: {
                active: false,
            },
        };

        return state;
    },
    [CLOSE_ALL_MODALS]: (state: State) => {
        Object.keys(state.modals).map(id => {
            state.modals = {
                ...state.modals,
                [id]: {
                    active: false,
                },
            };
        });

        return state;
    },
};

export const modalStore = createStore<State>({
    initialState,
    actions,
});

export const addModal = (id: string) => {
    modalStore.dispatch(ADD_MODAL, { id });
};

export const closeModal = (id: string) => {
    modalStore.dispatch(CLOSE_MODAL, { id });
};

export const openModal = (id: string) => {
    modalStore.dispatch(OPEN_MODAL, { id });
};

export const closeAllModals = () => {
    modalStore.dispatch(CLOSE_ALL_MODALS);
};
