import { useListen, useRef } from '@atomify/hooks';
import { rafThrottle } from '@source/utilities/raf-throttle';

type ScrollPosition = { x: number; y: number };
export type ScrollCallback = {
    prevPosition: ScrollPosition;
    currentPosition: ScrollPosition;
};

/**
 * Hook that returns the browser viewport X and Y scroll position.
 * @param {({
 *         currentPosition,
 *         prevPosition,
 *     }: {
 *         prevPosition: ScrollPosition;
 *         currentPosition: ScrollPosition;
 *     }) => void} callback
 * @param {boolean} [useWindow]
 * @param {number} [wait]
 */
export const useScrollPosition = (
    callback: ({ currentPosition, prevPosition }: ScrollCallback) => void,
    useWindow?: boolean,
) => {
    const position = useRef<ScrollPosition>(getScrollPosition(useWindow));

    function setScrollPosition() {
        const currentPosition = getScrollPosition(useWindow);
        callback({ prevPosition: position.current, currentPosition });
        position.current = currentPosition;
    }

    const throttle = rafThrottle(setScrollPosition);

    useListen(window, 'scroll', throttle);
};

/**
 * Returns the current scroll position
 * @param {(boolean | undefined)} useWindow
 * @returns
 */
function getScrollPosition(useWindow: boolean | undefined) {
    const target = document.body;
    const position = target.getBoundingClientRect();

    return useWindow
        ? { x: window.scrollX, y: window.scrollY }
        : { x: position.left, y: position.top };
}
