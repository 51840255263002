import { Component, Listen, Query, QueryAll } from '@atomify/core';
import { BPDForm } from '@source/components/atoms/form-elements';
import { CookiesInterface } from '@source/components/molecules/cookies/utilities/cookies-types';

@Component({
    tag: 'bpd-cookies-list',
    style: ':host { display: block }',
})
export class CookiesList extends HTMLElement {
    @Query('bpd-cookies', document) cookies: CookiesInterface;
    @Query('[js-hook-cookie-form]') form: BPDForm;
    @QueryAll('input') inputs: HTMLInputElement[];
    @Listen('serialize-form', { target: 'form' })
    formSubmitted(e: CustomEvent) {
        const { fields } = e.detail;
        const inputs = [...this.inputs];
        const cookieValues: { [key: string]: string } = {};

        inputs.forEach(({ value: cookieName }) => {
            const cookieValue = fields[cookieName] !== '' ? '1' : '0';

            this.cookies.setCookie(cookieName, cookieValue);
            cookieValues[cookieName] = cookieValue;
        });
        this.cookies.addGlobalCookies(cookieValues);

        window.location.href = window.location.origin;
    }
    componentDidLoad() {
        this.setInputState();
        this.cookies.removeCookiebar();
    }
    private setInputState() {
        const inputs = [...this.inputs];
        inputs.forEach(input => {
            const { value, disabled } = input;
            const cookieValue = this.cookies.getCookie(value);

            // Check disabled state only then update checked state.
            if (!disabled) {
                input.checked = cookieValue === '1';
            }
        });
    }
}
