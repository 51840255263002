import { delegate } from '@atomify/core';
interface DelegateOptions {
    target?: EventTarget;
    useCapture?: boolean;
}
export const useDelegateListen = (
    selector: string,
    type: string,
    callback: (e: Event) => void,
    options: DelegateOptions = {},
) => {
    delegate({
        target: options.target || document,
        selector,
        type,
        callback,
    });
};
