import { Component, Prop, Watch } from '@atomify/core';
import { Breakpoints, max, mediaWatcher } from '@source/utilities/media-query';

const ARIA_EXPANDED = 'aria-expanded';
const ARIA_HIDDEN = 'aria-hidden';
const COLLAPSED_BREAKPOINT = 'data-collapsed-breakpoint';
const COLLAPSE_ACTIVE_CLASS = 'content-collapse--is-collapsed';
export interface ContentCollapseInterface extends HTMLElement {
    expanded: boolean;
}
@Component({
    tag: 'bpd-content-collapse',
})
export class ContentCollapse extends HTMLElement {
    heightTransitionEvent: () => void;

    @Prop({ reflectToAttribute: true, type: 'Boolean' }) expanded: boolean = false;
    @Prop() defaultHeight: string = '0';

    _relatedToggle: HTMLElement | null;

    /**
     * Gets the related button through aria controls.
     * @readonly
     * @memberof ContentCollapse
     */
    get relatedToggle() {
        if (!this._relatedToggle) {
            this._relatedToggle = document.querySelector(`[aria-controls="${this.id}"]`);
        }

        return this._relatedToggle;
    }

    @Watch('expanded')
    expandedChanged(newValue: boolean) {
        this.setAriaAttributes(newValue);
        this.handleAnimation();

        if (!newValue) {
            this.classList.remove(COLLAPSE_ACTIVE_CLASS);
        }
    }

    componentDidLoad() {
        const isHidden = this.getAttribute(ARIA_HIDDEN);
        const collapsedBreakpoint = this.getAttribute(COLLAPSED_BREAKPOINT) as Breakpoints;

        this.appendControlEvents();

        if (isHidden === 'false') {
            this.expanded = true;
        }

        if (collapsedBreakpoint) {
            mediaWatcher(max(collapsedBreakpoint), matches => {
                if (matches) {
                    this.expanded = false;
                } else if (isHidden === 'false') {
                    this.expanded = true;
                }
            });
        }
    }

    /**
     * Append click event listener to related toggle.
     * @memberof ContentCollapse
     */
    private appendControlEvents() {
        this.relatedToggle?.addEventListener('click', () => (this.expanded = !this.expanded));
    }

    /**
     * Adds the correct aria attributes to the related button and the <content-collapse>.
     * @private
     * @param {boolean} state
     * @memberof ContentCollapse
     */
    private setAriaAttributes(state: boolean) {
        this.relatedToggle?.setAttribute(ARIA_EXPANDED, `${state}`);
        this.setAttribute(ARIA_HIDDEN, `${!state}`);
    }

    /**
     * Applies the new calculated height to the <content-collapse>.
     * @private
     * @memberof ContentCollapse
     */
    private handleAnimation() {
        const height = this.firstElementChild?.clientHeight;

        if (height) {
            this.heightTransitionEvent = () => this.heightTransitionEnd();
            this.setHeight(`${height}px`);
            this.addEventListener('transitionend', this.heightTransitionEvent, false);
        }
    }

    /**
     * Sets the height based up on the expanded property.
     * @private
     * @param {string} height
     * @memberof ContentCollapse
     */
    private setHeight(height: string) {
        this.style.height = this.expanded ? this.defaultHeight : height;
        this.offsetHeight;
        this.style.height = this.expanded ? height : this.defaultHeight;
    }

    /**
     * Function that gets fired after the transition is ended and applies collapsed class.
     * @private
     * @memberof ContentCollapse
     */
    private heightTransitionEnd() {
        if (this.expanded) {
            this.style.height = 'auto';
            this.classList.add(COLLAPSE_ACTIVE_CLASS);
        }

        this.removeEventListener('transitionend', this.heightTransitionEvent, false);
    }
}
