import { h } from '@atomify/jsx';

interface RowInterface {
    extraClass?: string;
    children?: NodeList;
}

export const Row = ({ extraClass = '', children }: RowInterface) => (
    <div class={`o-grid ${extraClass}`}>{children}</div>
);
