import { h } from '@atomify/jsx';

interface InputProps {
    validationTypes?: string | null;
    validationRegex?: string | null;
    validationErrorMessage?: string | null;
    requiredErrorMessage?: string | null;
    value?: any;
    classes?: string;
    variant?: 'bordered' | 'default';
    disabled?: boolean;
    name: string;
    id: string;
    required?: boolean;
    hook?: string;
    attr?: { [key: string]: string };
    label: string | Text;
    error?: string;
    type?: string;
    labelAsPlaceholder?: boolean;
    sronly?: boolean;
    placeholder?: string;
    displayName?: string | null;
}

export const InputElement = ({
    validationTypes,
    validationRegex,
    validationErrorMessage,
    requiredErrorMessage,
    value,
    classes,
    variant,
    disabled,
    name,
    id,
    required,
    hook,
    attr,
    label,
    error,
    type = 'text',
    labelAsPlaceholder,
    sronly,
    placeholder,
    displayName,
}: InputProps) => {
    const inputClasses = {
        ['c-input']: true,
        ['form__item']: true,
        ['form__item--disabled']: disabled,
        ['form__item--lap']: labelAsPlaceholder,
        ['input--bordered']: variant === 'bordered',
    };

    return (
        <bpd-input
            validation-types={validationTypes}
            validation-regex={validationRegex}
            validation-error-message={validationErrorMessage}
            required-error-message={requiredErrorMessage}
            bindValue={value}
            class={classes}
            displayName={displayName}>
            <div class={inputClasses}>
                {type === 'hidden' ? (
                    <input
                        class="input__input"
                        name={name}
                        id={id}
                        required={required}
                        disabled={disabled}
                        value={value}
                        type={type}
                        placeholder={labelAsPlaceholder ? null : placeholder}
                        label-associated={label}
                        {...{ [`js-hook-${hook ? hook : ''}`]: !!hook, ...attr }}
                    />
                ) : (
                    <div class="input__wrapper">
                        <label class={{ ['input__label']: true, ['u-sr-only']: sronly }} for={id}>
                            {label}
                        </label>

                        <input
                            class="input__input"
                            name={name}
                            id={id}
                            required={required}
                            disabled={disabled}
                            type={type}
                            placeholder={labelAsPlaceholder ? null : placeholder}
                            label-associated={label}
                            {...{ [`js-hook-${hook ? hook : ''}`]: !!hook, ...attr }}
                        />
                    </div>
                )}

                <div class="form__item-error" role="alert">
                    {error}
                </div>
            </div>
        </bpd-input>
    );
};
