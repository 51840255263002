import { elements } from '@utilities/dom';

const HAS_POLYFILL_CLASS = 'has--object-fit-polyfill';
const OBJECT_FIT_IMAGE_HOOK = 'js-hook-objectfit-img';
const OBJECT_FIT_CONTAINER_HOOK = 'js-hook-objectfit-container';

type ImageElement = HTMLImageElement | HTMLImageElement[];

export default class ObjectFit {
    public static polyfillObjectFit(element: ImageElement) {
        if ('objectFit' in document.documentElement.style) {
            return;
        }

        const images = ObjectFit.getObjectfitImages(element);
        elements.html.classList.add(HAS_POLYFILL_CLASS);
        images.forEach(image => ObjectFit.polyfillImage(image));
    }

    public static getObjectfitImages(element: ImageElement) {
        let images: ImageElement = [];

        if (!Array.isArray(element)) {
            // If element without hook is passed in, ignore it.
            if (
                typeof element.getAttribute(OBJECT_FIT_IMAGE_HOOK) === 'undefined' ||
                element.getAttribute(OBJECT_FIT_IMAGE_HOOK) === null
            ) {
                return images;
            }
            images.push(element);
        } else {
            const queriedImages = document.querySelectorAll(`[${OBJECT_FIT_IMAGE_HOOK}]`);
            images = [...(queriedImages as any)];
        }

        return images;
    }

    private static polyfillImage(image: HTMLImageElement) {
        const container = image.closest(`[${OBJECT_FIT_CONTAINER_HOOK}]`);
        if (container)
            container.setAttribute('style', `background-image: url(${image.currentSrc});`);
    }
}
