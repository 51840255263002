import { delegate } from '@atomify/core';
import { closeAllModals, openModal } from '@source/store/modals';
import { closeModal } from '@store/modals';

const ARIA_CONTROLS = 'aria-controls';
const CLOSE_ALL_MODALS = 'data-close-all-modals';
const MODAL_ACTION = 'data-modal-action';
const MODAL_TRIGGER = '[js-hook-modal-trigger]';
const DELEGATE_EVENT = 'click';
const DELEGATE_TARGET = document;

interface ModalControllerOptions {
    ariaControls?: string;
    modalTrigger?: string;
    delegateEvent?: string;
    delegateTarget?: EventTarget;
}

/**
 * Basic modal controller that updates a modal open/close state to the store.
 */
export const modalController = (options?: ModalControllerOptions) => {
    delegate({
        selector: options?.ariaControls || MODAL_TRIGGER,
        target: options?.delegateTarget || DELEGATE_TARGET,
        type: options?.delegateEvent || DELEGATE_EVENT,
        callback: event => {
            const target = (event as any).delegateTarget as HTMLButtonElement;
            const hasAriaControls = target.hasAttribute(ARIA_CONTROLS);
            const hasCloseAllModals = target.hasAttribute(CLOSE_ALL_MODALS);

            // Check if the button has aria controls so we have reference for the modal ID.
            if (hasAriaControls) openModalById(target);

            // Check if the button has modals that it needs to close.
            if (hasCloseAllModals) closeAllModals();
        },
    });
};

/**
 * Opens modal based upon ID
 * @param {HTMLButtonElement} target
 */
const openModalById = (target: HTMLButtonElement) => {
    const modalId = target.getAttribute(ARIA_CONTROLS);
    const modalAction = target.getAttribute(MODAL_ACTION);

    if (!modalId) return;

    switch (modalAction) {
        case 'open':
            return openModal(modalId);
        case 'close':
            return closeModal(modalId);
        default:
            return openModal(modalId);
    }
};
