import {
    defineElement,
    FC,
    onDidLoad,
    useElement,
    useElements,
    useEvent,
    useListen,
    useProp,
} from '@atomify/hooks';
import { h } from '@atomify/jsx';
import { BUTTON_LOADING_CLASS } from '@source/components/atoms/button/button';
import { BPDForm, BPDInput } from '@source/components/atoms/form-elements';
import { PagesProps } from '@source/components/atoms/pages';
import { FormByIdResponse, getFormById, postFormById } from '@source/services/forms';
import { LAZY_HOOKS, useLazy, useMapStorage } from '@source/utilities/hooks';

import { generateForm } from './utilities';

export interface SectionFormProps {
    bpdPages: PagesProps;
    submitButton: HTMLButtonElement;
    inputs: BPDInput[];
}

export const SectionForm: FC<SectionFormProps> = ({ element }) => {
    const [formId] = useProp<string>('formId', '');
    const [, setLoading] = useProp<boolean>('loading', true);
    const { setItem, hasItem, deleteItem, getItem } = useMapStorage<string, any>();

    const event = useEvent<{ fields: any; title: string }>({
        eventName: 'form-submitted',
        target: document,
    });

    const [LazyResult, fetchForm] = useLazy<FormByIdResponse>({
        fetcher: () => getFormById(formId),
        loadingResult,
        errorResult,
        successResult,
    });

    onDidLoad(async () => {
        await fetchForm();

        setLoading(false);
    });

    // Element query bindings for dynamic use.
    useElement<PagesProps>('bpd-pages', { as: 'bpdPages' });
    useElement<HTMLButtonElement>('.page--is-selected [js-hook-submit]', { as: 'submitButton' });
    useElements<BPDInput[]>(
        'bpd-form:not([completed]) bpd-radio, bpd-form:not([completed]) bpd-input',
        { as: 'inputs' },
    );

    useListen(document, 'serialize-form', async (e: CustomEvent) => {
        const { fields, form } = e.detail;

        if (form.id !== formId) return;

        if (form.saveWhenComplete) {
            try {
                setButtonLoadingState(true);
                await postFormById(formId, fields);
                saveAndPrefillFormFields(fields, form);
                event.emit({ fields, title: element.getAttribute('data-gtm-title') || '' });
            } catch (error) {
                console.error(error);
                setButtonLoadingState(false);
            }
        } else {
            saveAndPrefillFormFields(fields, form);
        }
    });

    /**
     * Saves the fields and the values and prefills the values of related bpd-input/bpd-radio
     * @param {{ [key: string]: any }} fields
     */
    function saveAndPrefillFormFields(fields: { [key: string]: any }, form: BPDForm) {
        form.completed = true;

        // Loop through the submitted form fields and save them
        Object.keys(fields).map(fieldName => {
            const fieldValue = fields[fieldName];

            // Check if form field is saved.
            // Delete and readd the form field if its saved.
            if (!hasItem(fieldName)) {
                setItem(fieldName, fieldValue);
            } else {
                deleteItem(fieldName);
                setItem(fieldName, fieldValue);
            }
        });

        // Loop through inputs of not completed forms and prefill them if they are part of the map.
        Array.from(element.inputs).map(input => {
            const { displayName, rebindValue } = input;

            // Check if the value of the input is already saved.
            // Re-bind the value if the input is already saved.
            if (hasItem(displayName)) {
                const value = getItem(displayName);
                rebindValue(value);
            }
        });

        element.bpdPages.selectNext();
    }

    /**
     * Set button loading state
     * @param {boolean} state
     */
    function setButtonLoadingState(state: boolean) {
        const action = state ? 'add' : 'remove';
        if (element.submitButton) element.submitButton.classList[action](BUTTON_LOADING_CLASS);
    }

    /**
     * Default loading state of the form
     * @returns { HTMLElement }
     */
    function loadingResult() {
        return <div {...{ [LAZY_HOOKS.LAZY_LOADING_HOOK]: true }}></div>;
    }

    /**
     * Default error state of the form
     * @returns { HTMLElement }
     */
    function errorResult() {
        return (
            <div {...{ [LAZY_HOOKS.LAZY_ERROR_HOOK]: true }}>
                Er is iets misgegaan met het ophalen van het formulier
            </div>
        );
    }

    /**
     * Succes state when API call is finished
     * @param {FormByIdResponse} data
     * @returns { HTMLElement }
     */
    function successResult(data: FormByIdResponse) {
        return (
            <bpd-pages {...{ [LAZY_HOOKS.LAZY_SUCCESS_HOOK]: true }} selector={'bpd-form'}>
                {generateForm(data, formId)}
            </bpd-pages>
        );
    }

    return <LazyResult />;
};

SectionForm.props = {
    formId: {
        type: String,
    },
    loading: {
        type: Boolean,
        reflectToAttr: true,
    },
};

defineElement('bpd-section-form', SectionForm);
