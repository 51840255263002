export const isSVG = (tagName: string) => {
    const SVGTags = [
        'altGlyph',
        'altGlyphDef',
        'altGlyphItem',
        'animate',
        'animateColor',
        'animateMotion',
        'animateTransform',
        'animation',
        'audio',
        'canvas',
        'circle',
        'clipPath',
        'color-profile',
        'cursor',
        'defs',
        'desc',
        'discard',
        'ellipse',
        'feBlend',
        'feColorMatrix',
        'feComponentTransfer',
        'feComposite',
        'feConvolveMatrix',
        'feDiffuseLighting',
        'feDisplacementMap',
        'feDistantLight',
        'feDropShadow',
        'feFlood',
        'feFuncA',
        'feFuncB',
        'feFuncG',
        'feFuncR',
        'feGaussianBlur',
        'feImage',
        'feMerge',
        'feMergeNode',
        'feMorphology',
        'feOffset',
        'fePointLight',
        'feSpecularLighting',
        'feSpotLight',
        'feTile',
        'feTurbulence',
        'filter',
        'font',
        'font-face',
        'font-face-format',
        'font-face-name',
        'font-face-src',
        'font-face-uri',
        'foreignObject',
        'g',
        'glyph',
        'glyphRef',
        'handler',
        'hkern',
        'iframe',
        'image',
        'line',
        'linearGradient',
        'listener',
        'marker',
        'mask',
        'metadata',
        'missing-glyph',
        'mpath',
        'path',
        'pattern',
        'polygon',
        'polyline',
        'prefetch',
        'radialGradient',
        'rect',
        'script',
        'set',
        'solidColor',
        'stop',
        'style',
        'svg',
        'switch',
        'symbol',
        'tbreak',
        'text',
        'textArea',
        'textPath',
        'title',
        'tref',
        'tspan',
        'unknown',
        'use',
        'video',
        'view',
        'vkern',
    ];
    return SVGTags.indexOf(tagName) >= 0;
};
