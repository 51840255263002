import QueryString from 'query-string';

import { LoadPageOptions } from './types';

/**
 * Generates url based upon options ex: { params: { page: 1, status: 'bug' }, url: 'https://localhost:3000/'} => https://localhost:3000?page=1&status=bug
 * @param {LoadPageOptions} data
 * @returns {string}
 */
export function generateUrl(data: LoadPageOptions) {
    const [initialUrl, anchorLink] = window.location.href.split('#');
    const [url, queryString] = data.url ? data.url.split('?') : initialUrl.split('?');

    let params = QueryString.parse(queryString);
    let nextPageUrl;

    if (data.params && Object.keys(data.params).length !== 0)
        params = Object.assign(params, data.params);

    if (Object.keys(params).length !== 0) {
        nextPageUrl = `${url}?${QueryString.stringify(params)}${
            anchorLink ? `#${anchorLink}` : ''
        }`;
    } else {
        nextPageUrl = url;
    }

    return nextPageUrl;
}

/**
 * Appends children to a HTMLElement
 * @param {HTMLElement} parent
 * @param {HTMLElement[]} children
 * @param {string} className
 * @returns
 */
export function appendChildren(parent: HTMLElement, children: HTMLElement[], className: string) {
    for (let i = 0; i < children.length; i++) {
        const child = children[i];

        if (className) child.classList.add(className);
        parent.appendChild(child);
    }

    return parent;
}

/**
 * Makes sure that the event is ran once instead of multiple times.
 * @param {HTMLElement} el
 * @param {string} type
 * @param {(e: any) => void} fn
 */
export function once(el: HTMLElement, type: string, fn: (e: any) => void) {
    function handler(event: any) {
        el.removeEventListener(type, handler);
        fn(event);
    }

    el.addEventListener(type, handler);
}

/**
 * Converts a string into HTML
 * @param {string} html
 * @returns {DocumentFragment}
 */
export function htmlToElement(html: string) {
    const template = document.createElement('template');

    html = html.trim();

    template.innerHTML = html;

    return template.content;
}
