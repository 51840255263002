import { useBindMethod, useElement, useElements, useEvent, useListen } from '@atomify/hooks';

interface UseInput {
    inputElements: string;
    errorTarget: string;
    inputLabelTarget?: string;
    onRebindValue?: (value: any) => void;
    onValidate: () => void;
    onInputChange?: (e?: any) => void;
    onInputFocus?: (e?: any) => void;
    onInputBlur?: (e?: any) => void;
    onInputInput?: (e?: any) => void;
    onInputFocusout?: (e?: any) => void;
    resetInput?: () => void;
}

export interface UseInputElement {
    rebindValue: (value: any) => void;
    validate: () => boolean;
    resetInput?: () => void;
}

export const useInput = ({
    inputElements,
    errorTarget,
    inputLabelTarget = '',
    onInputChange = () => null,
    onInputFocus = () => null,
    onInputBlur = () => null,
    onInputInput = () => null,
    onInputFocusout = () => null,
    resetInput = () => null,
    onRebindValue = () => null,
    onValidate,
}: UseInput) => {
    const inputs = useElements<HTMLInputElement[]>(inputElements);
    const errorHolder = useElement<HTMLElement>(errorTarget);
    const inputLabel = inputLabelTarget
        ? useElement<HTMLLabelElement>(inputLabelTarget)
        : { current: null };

    const bindValueChanged = useEvent<any>({ eventName: 'bindValueChanged' });
    const inputValidate = useEvent<{
        errorMessage: string;
        value: any;
        isValid: boolean;
        input: HTMLInputElement;
    }>({
        eventName: 'input-validate',
    });

    useListen(inputs, 'change', onInputChange);
    useListen(inputs, 'focus', onInputFocus);
    useListen(inputs, 'blur', onInputBlur);
    useListen(inputs, 'input', onInputInput);
    useListen(inputs, 'focusout', onInputFocusout);

    useBindMethod('resetInput', resetInput);

    /**
     * Adds a rebindValue function to the input component
     */
    useBindMethod('rebindValue', onRebindValue);

    /**
     * Adds a validate function to the input component
     * It validates the component based upon validation types and the value.
     */
    useBindMethod('validate', onValidate);

    return {
        inputs,
        errorHolder,
        inputLabel,
        bindValueChanged,
        inputValidate,
    };
};
