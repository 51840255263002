import { Component, Prop } from '@atomify/core';
import { h } from '@atomify/jsx';

@Component({
    tag: 'bpd-svg',
})
export class BPDSvg extends HTMLElement {
    @Prop() name: string = '';

    render() {
        return (
            <svg class={`svg svg-${this.name}`}>
                <use xlinkHref={`#svg-${this.name}`}></use>
            </svg>
        );
    }
}

export const BPDFunctionalSvg = ({ name }: { name: string }) => (
    <svg class={`svg svg-${name}`}>
        <use xlinkHref={`#svg-${name}`}></use>
    </svg>
);
