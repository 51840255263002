import { h } from '@atomify/jsx';

import createImageUrl from './utilities/create-image-url';
import { Setting, Settings } from './utilities/image';

export interface ImageInterface {
    image: string;
    alt: string;

    settings?: Settings;
    preload?: Setting;

    // Remove after settings is implemented
    srcset?: string;

    classes?: string;
    caption?: string;

    hidden?: boolean;
    objectFit?: boolean | 'contain';
    disableCrossFade?: boolean;
    mode?: string
}

export const Image = (data: ImageInterface) => {
    const preload = data.preload ? createImageUrl(data.image, data.preload, { mode: data.mode || "crop" }) : false;

    return (
        <bpd-image class={`c-image ${data.classes ? data.classes : ''}`}>
            <figure {...{ 'js-hook-objectfit-container': data.objectFit }}>
                <img
                    class={`${data.disableCrossFade ? '' : ' image__default'}`}
                    data-image={data.image}
                    alt={data.alt}
                    {...{
                        src: preload ? preload : false,
                        'data-settings': JSON.stringify(data.settings),
                        'js-hook-objectfit-img': data.objectFit,
                        hidden: data.hidden,
                    }}
                />
            </figure>

            {data.caption && <figcaption class="image__caption">{data.caption}</figcaption>}
        </bpd-image>
    );
};
