import { defineElement, FC, onDidLoad, useBindMethod, useProp, useRef } from '@atomify/hooks';
import { useStylesheet } from '@source/utilities/hooks';
import { transitionHiddenElement } from '@source/utilities/transition-hidden-element';

import style from './pages.scss';

const SELECTED_CLASS = 'page--is-selected';

export interface PagesProps {
    selectNext: () => void;
    selectPrev: () => void;
    setPage: (value: string) => void;
}

export const Pages: FC<PagesProps> = ({ element }) => {
    useStylesheet(style);

    const [selected, setSelected, selectedChanged] = useProp('selected', 0);
    const [attrForSelected] = useProp<string | null>('attrForSelected', null);
    const [animated] = useProp('animated', false);
    const [selector] = useProp('selector', 'div');
    const [selectedClass] = useProp('selectedClass', SELECTED_CLASS);

    const currentPage = useRef<HTMLDivElement | null>();
    const pagesValue = useRef(0);

    const currentAnimated = useRef<number | null>(null);
    const animatedPages = useRef<any>([]);
    const pages = useRef<HTMLDivElement[]>([]);

    selectedChanged(newValue => setPage(`${newValue}`));

    onDidLoad(() => {
        pages.current = getPages();
        if (animated) initAnimatedPages();
        setPage(`${selected}`);
    });

    useBindMethod('selectNext', selectNext);
    useBindMethod('selectPrev', selectPrev);
    useBindMethod('setPage', setPage);

    function selectNext() {
        const index = pagesValue.current;

        if (index + 1 >= pages.current.length) {
            setSelected(indexToValue(0));
        } else {
            setSelected(indexToValue(index + 1));
        }
    }

    function selectPrev() {
        const index = pagesValue.current;

        if (index - 1 < 0) {
            setSelected(indexToValue(pages.current.length - 1));
        } else {
            setSelected(indexToValue(index - 1));
        }
    }

    /**
     * Transform index to value
     * @param {number} index
     * @returns
     */
    function indexToValue(index: number) {
        let page;

        if (attrForSelected) {
            page = pages.current[index];
            if (page) return Number(getValue(page));
        } else {
            return index;
        }

        return 0;
    }

    /**
     * Initialize animated pages
     */
    function initAnimatedPages() {
        const items = Array.from(pages.current) || [];
        items.forEach(item => {
            const transitioner = transitionHiddenElement({
                element: item,
                visibleClass: selectedClass,
            });
            animatedPages.current = [...animatedPages.current, transitioner];
        });
    }

    /**
     * Set current page
     * @param {number} page
     */
    function setPage(page: string) {
        if (pages.current && pages.current.length) {
            pagesValue.current = valueToIndex(`${page}`);

            if (animated) {
                setAnimatedPage();
            } else {
                setNormalPage();
            }
        }
    }

    /**
     * Set animated page when animated attribute is true
     */
    function setAnimatedPage() {
        if (animatedPages.current.length > 0) {
            if (typeof currentAnimated.current === 'number')
                animatedPages.current[currentAnimated.current].hide();

            animatedPages.current[pagesValue.current].show();
            currentAnimated.current = pagesValue.current;
        }
    }

    /**
     * Set normal page when animated attribute is not set
     */
    function setNormalPage() {
        if (currentPage.current) currentPage.current.classList.remove(`${selectedClass}`);

        pages.current![pagesValue.current].classList.add(`${selectedClass}`);
        currentPage.current = pages.current![pagesValue.current];
    }

    /**
     * Gets the childeren that function as pages
     * @returns {HTMLDivElement[]}
     */
    function getPages() {
        const pages = element.querySelectorAll<HTMLDivElement>(`${selector}`);
        return Array.from(pages);
    }

    /**
     * Transforms a value to index of the pages
     * @param {string} value
     * @returns
     */
    function valueToIndex(value: string) {
        if (attrForSelected && pages.current) {
            for (let i = 0; i < pages.current.length; i++) {
                if (getValue(pages.current[i]) === value) {
                    return i;
                }
            }
        } else {
            return Number(value);
        }

        return 0;
    }

    /**
     * @param {HTMLDivElement} page
     * @returns
     */
    function getValue(page: HTMLDivElement) {
        return attrForSelected ? page.getAttribute(attrForSelected) : '';
    }
};

Pages.props = {
    selected: {
        type: Number,
        reflectToAttr: true,
    },
    animated: {
        type: Boolean,
        reflectToAttr: true,
    },
    selector: {
        type: String,
        reflectToAttr: true,
    },
    selectedClass: {
        type: String,
        reflectToAttr: true,
    },
    attrForSelected: {
        type: String,
        reflectToAttr: true,
    },
};

defineElement('bpd-pages', Pages);
