export const BOUND_LISTENERS = '__boundListeners';
export const CLASS_PROPERTIES = '__classProperties';
export const WATCHED_PROPERTIES = '__watchedProperties';
export const REFLECTED_PROPERTIES_TO_ATTRIBUTE = '__reflectedPropertiesToAttribute';
export const IS_REFLECTING_PROPERTY = '__isReflectingToProperty';
export const IS_REFLECTING_ATTRIBUTE = '__isReflectingToAttribute';
export const PROPERTIES = 'properties';
export const BOUND_EVENTS = '__boundEvents';
export const IS_DISCONNECTING = '__isDisconnecting';
export const ON_READY_RESOLVED = '__onReadyResolve';
export const ELEMENT_ID = '__elementId';
