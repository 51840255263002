import { useListen } from '@atomify/hooks';

export type FormItem = {
    key: string;
    value: FormDataEntryValue;
    element: Element | null;
};

export type FormChangeCallback = {
    additions: FormItem[];
    deletions: FormItem[];
};

let previousState: FormItem[] = [];

function setPreviousState(form: { current: HTMLFormElement | null }) {
    previousState = [];
    if (form.current) {
        const entries = new FormData(form.current).entries();
        for (const [key, value] of entries) {
            const element = form.current.querySelector(`input[name="${key}"][value="${value}"]`);
            previousState.push({ key, value, element });
        }
    }
}

export function useFormChanges(
    form: { current: HTMLFormElement | null },
    callback: ({ additions, deletions }: FormChangeCallback) => void,
) {
    useListen(form, 'change', () => {
        if (form.current) {
            const formData = new FormData(form.current);
            const entries = formData.entries();
            const currentState: FormItem[] = [];

            for (const [key, value] of entries) {
                let element = form.current.querySelector(`input[name="${key}"][value="${value}"]`);

                if (!element) {
                    element = form.current.querySelector(
                        `input[name="${key}"][js-hook-slider-min-max-value]`,
                    );
                }

                currentState.push({ key, value, element });
            }

            const additions = currentState.filter(
                itemA =>
                    !previousState.find(
                        itemB => itemB.key === itemA.key && itemB.value === itemA.value,
                    ),
            );

            const deletions = previousState.filter(
                itemA =>
                    !currentState.find(
                        itemB => itemB.key === itemA.key && itemB.value === itemA.value,
                    ),
            );

            setPreviousState(form);

            callback({ additions, deletions });
        }
    });
}
