import { h } from '@atomify/jsx';
import { BPDFunctionalSvg } from '@source/components/atoms/svg/svg';

interface ButtonInterface {
    label: string | Text;
    variant?: string;
    size?: string;
    classes?: string;
    type?: string;
    url?: string;
    hook?: string;
    controls?: string;
    onClick?: Function;
    target?: string;
    icon?: string;
    attr?: {};
    children?: Element;
}

const BUTTON_LOADING_CLASS = 'button--is-loading';

const createClassName = (data: ButtonInterface) => {
    let className = data.variant ? `c-button--${data.variant}` : 'c-button';

    className += data.size ? ` button--${data.size}` : '';
    className += data.classes ? ` ${data.classes}` : '';

    return className;
};

const createAttributes = (data: ButtonInterface) => {
    const attributes: any = {
        ...data.attr,
        [`js-hook-${data.hook}`]: data.hook,
        onClick: data.onClick,
        'aria-controls': data.controls,
        href: data.url,
    };

    return attributes;
};

const Button = (data: ButtonInterface) => {
    const className = createClassName(data);
    const attributes = createAttributes(data);

    attributes.type = data.type || 'button';

    return (
        <button class={className} {...attributes}>
            {data.icon && (
                <span class="button__icon">
                    <BPDFunctionalSvg name={data.icon} />
                </span>
            )}

            <span class="button__label">
                {data.label}
                {data.children}
            </span>
        </button>
    );
};

const ButtonA = (data: ButtonInterface) => {
    const className = createClassName(data);
    const attributes = createAttributes(data);

    return (
        <a class={className} {...attributes} target={data.target}>
            <span class="button__label">{data.label}</span>
        </a>
    );
};

export { Button, ButtonA, BUTTON_LOADING_CLASS };
