import {
    Component,
    defineElement,
    FC,
    useElement,
    useElements,
    useEvent,
    useListen,
    useProp,
    useReactive,
    useRef,
    useWatch,
} from '@atomify/hooks';
import { useClickOutside } from '@source/utilities/hooks';
const FILTER_DROPDOWN_ACTIVE = 'filter-dropdown--is-active';

export interface BPDFilterDropdown extends Component {
    title: string;
}

const FilterDropdown: FC = ({ element }) => {
    const [name] = useProp<string>('name');
    const [] = useProp<string>('title', '');
    const button = useElement<HTMLButtonElement>('[js-hook-filter-dropdown-button]');
    const inputElements = useElements<HTMLElement[]>('bpd-input, bpd-radio');
    const dropdownCloseElement = useElements<HTMLElement[]>(
        '[js-hook-filter-dropdown-close-trigger]',
    );

    const dropdownActive = useRef<boolean>(false);
    const dropdownValues = useReactive<{ filterValues: string[] }>({ filterValues: [] });

    const filterChangedEvent = useEvent<{ [key: string]: string[] }>({
        eventName: 'filter-changed',
    });

    // Close the dropdown when clicking outside of the component
    useClickOutside(element, clickOutside => {
        if (clickOutside) {
            dropdownActive.current = false;
        }
    });

    useListen(button, 'click', openDropdown);
    useListen(dropdownCloseElement, 'click', closeDropdown);
    useListen(inputElements, 'input-validate', filterChanged);

    function filterChanged(e: CustomEvent) {
        const { input, value } = e.detail;

        if (value) {
            dropdownValues.filterValues = [...dropdownValues.filterValues, input.value];
        } else {
            dropdownValues.filterValues = dropdownValues.filterValues.filter(
                item => item !== input.value,
            );
        }

        filterChangedEvent.emit({ [name]: dropdownValues.filterValues });
    }

    // Watch dropdownactive ref
    useWatch(() => {
        toggleDropdown(dropdownActive.current);
    });

    function openDropdown(e: MouseEvent) {
        e.preventDefault();
        dropdownActive.current = !dropdownActive.current;
    }

    function closeDropdown(e: MouseEvent) {
        e.preventDefault();
        dropdownActive.current = false;
    }

    function toggleDropdown(state: boolean) {
        const action = state ? 'add' : 'remove';
        element.classList[action](FILTER_DROPDOWN_ACTIVE);
    }
};

FilterDropdown.props = {
    name: {
        type: String,
        reflectToAttr: true,
        required: true,
    },
    title: {
        type: String,
        reflectToAttr: true,
    },
};

defineElement('bpd-filter-dropdown', FilterDropdown);
