import { Component, defineElement, FC, onDidLoad, useProp, useRef } from '@atomify/hooks';
import { useInput, UseInputElement } from '@source/utilities/hooks';

// Local imports
import { validateCustomRegex, validateField } from './validation';

export interface BPDRadio extends Component, UseInputElement {
    bindValue: string;
    valid: boolean;
    displayName: string;
}

export const Radio: FC<BPDRadio> = ({ element }) => {
    const [, setBindValue] = useProp<string>('bindValue', '');
    const [, setIsValid] = useProp<boolean>('valid', false);
    const [,] = useProp<string | null>('displayName', null);
    const [validationTypes] = useProp<string>('validationTypes', '');
    const [validationRegex] = useProp<string | null>('validationRegex', null);
    const [validationErrorMessage] = useProp<string | null>('validationErrorMessage', null);
    const [requiredErrorMessage] = useProp<string | null>('requiredErrorMessage', null);
    const [hideErrors] = useProp<boolean>('hideErrors', false);
    const [singleOptionAllowed] = useProp<boolean>('singleOptionAllowed', false);
    const [, setIsTouched] = useProp<boolean>('isTouched', false);
    const [, setIsFocussed] = useProp<boolean>('isFocussed', false);

    const inputElement = useRef<HTMLInputElement | null>(null);
    const errors = useRef<string[]>([]);
    const errorMessage = useRef<string>('');

    const { inputs, errorHolder, inputLabel, bindValueChanged, inputValidate } = useInput({
        inputElements: 'input',
        errorTarget: '[role="alert"]',
        inputLabelTarget: 'label',
        onInputChange,
        onInputFocus,
        onInputBlur,
        onRebindValue,
        onValidate,
        resetInput,
    });

    onDidLoad(() => {
        setInput();

        const input = inputElement.current;

        setBindValue(input ? input.value : '');
        setIsValid(!!input);
    });

    function onInputChange(e: Event) {
        setInput(e);
        const input = inputElement.current;

        if (input) {
            setBindValue(input.value);
            element.validate();
        }
    }

    function onInputFocus() {
        setIsFocussed(true);
        updateErrorValidState();
    }

    function onInputBlur() {
        setIsTouched(true);
        setIsFocussed(false);
    }

    function resetInput() {
        const input = inputElement.current;
        if (!input) return;

        if (input.type === 'radio') {
            input.checked = false;
            element.validate();
        }
    }
    /**
     * Adds a rebindValue function to the input component
     */
    function onRebindValue(value: any) {
        const input = inputElement.current;

        if (input) {
            setBindValue(value);
            input.checked = true;

            element.validate();
            bindValueChanged.emit(value);
        }
    }

    /**
     * Adds a validate function to the input component
     * It validates the component based upon validation types and the value.
     */
    function onValidate() {
        const inputElements = inputs.current;

        if (!inputElements) return true;

        const disabled = [...inputElements].filter(input => input && input.disabled).length > 0;
        const required = [...inputElements].filter(input => input && input.required).length > 0;
        const checked = [...inputElements].filter(input => input && input.checked).length > 0;

        if (disabled) return true;

        const isValidElement = !required || (required && checked);
        const value = isValidElement;

        // Set errors
        errors.current = getErrors(value);

        const hasErrors = errors.current.length === 0;

        // Set is valid property
        setIsValid(hasErrors);

        // Set error message
        errorMessage.current = element.valid ? '' : errors.current[0];

        // Emit input validate event.
        inputValidate.emit({
            errorMessage: errorMessage.current,
            value,
            isValid: element.valid,
            input: inputElement.current!,
        });

        updateErrorValidState();

        return element.valid;
    }

    /**
     * Sets and removes errors from the error holder based upon the active error message
     */
    function updateErrorValidState() {
        const inputElements = inputs.current;
        const errorContainer = errorHolder.current;

        if (!inputElements || !errorContainer) return;

        if (errors.current.length > 0) {
            element.setAttribute('invalid', '');

            [...inputElements].forEach(input => input && input.setAttribute('invalid', ''));

            element.removeAttribute('valid');

            if (!hideErrors) errorContainer.textContent = errorMessage.current;
        }

        if (element.valid) {
            element.setAttribute('valid', '');
            element.removeAttribute('invalid');
            [...inputElements].forEach(input => input && input.removeAttribute('invalid'));
            if (!hideErrors) errorContainer.textContent = '';
        }
    }

    /**
     * Get input errors based upon validation types.
     * @param {*} value
     */
    function getErrors(value: any) {
        const hasErrorValidation =
            validationTypes ||
            validationTypes.length > 0 ||
            validationRegex ||
            inputElement.current!.required;

        let errors: string[] = [];

        if (!hasErrorValidation) return errors;

        if (validationRegex || inputElement.current!.required) {
            errors = validateCustomRegex({
                value,
                required: inputElement.current!.required,
                requiredErrorMessage,
                validationRegex,
                validationErrorMessage,
            });
        }

        if (validationTypes || validationTypes.length > 0) {
            errors = validateField(value, validationTypes, inputLabel.current!);
        }

        return errors;
    }

    /**
     * Checks if there is an input and sets the current input
     */
    function setInput(e?: Event) {
        const input = inputs.current;

        if (input.length === 0) throw new Error(`There is no input[type="radio"] found.`);

        const inputNodes = [...input];
        const inputCheckedFiltered = inputNodes.filter(
            (item: HTMLInputElement) => item.checked === true,
        );

        if (inputCheckedFiltered.length > 0) {
            if (e && singleOptionAllowed && inputCheckedFiltered.length > 1) {
                const singleChoiceInput = e.currentTarget as HTMLInputElement;

                inputNodes.forEach((item: HTMLInputElement) => {
                    if (singleChoiceInput.id !== item.id) item.checked = false;
                });

                return (inputElement.current = singleChoiceInput);
            }
            return (inputElement.current = inputCheckedFiltered[0]);
        }

        if (inputNodes.length > 0) {
            return (inputElement.current = inputNodes[0]);
        }

        return (inputElement.current = null);
    }
};

Radio.props = {
    bindValue: {
        type: String,
    },
    valid: {
        type: Boolean,
        reflectToAttr: true,
    },
    validationTypes: {
        type: String,
    },
    hideErrors: {
        type: Boolean,
        reflectToAttr: true,
    },
    singleOptionAllowed: {
        type: Boolean,
        reflectToAttr: true,
    },
    isTouched: {
        type: Boolean,
    },
    isFocussed: {
        type: Boolean,
    },
    validationRegex: {
        type: String,
    },
    validationErrorMessage: {
        type: String,
    },
    requiredErrorMessage: {
        type: String,
    },
    displayName: {
        type: String,
    },
};

defineElement('bpd-radio', Radio);
