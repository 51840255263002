import { defineElement, useElement, useListen } from '@atomify/hooks';
import { AjaxContainer } from '@atoms/ajax-container';
import { useLoadMore } from '@atoms/load-more';

export const SearchList = () => {
    const [setLoading, onUpdateSearchReadmore, loadmoreElement] = useLoadMore('search-list');
    const ajaxContainer = useElement<AjaxContainer>('ajax-container');

    useListen(ajaxContainer, 'updated', () => {
        setLoading(false);
        loadmoreElement.current.parentNode.remove();
    });

    onUpdateSearchReadmore(({ page }) => {
        ajaxContainer.current!.loadPage({ params: { p: page } });
    });
};

defineElement('bpd-search-list', SearchList);
