const defaultProps = {
    environment: 'local',
    language: 'nl-NL',
    apiBaseUrl: 'https://bpd-api-web-test.azurewebsites.net',
    housingOverviewBaseUrl: '/housing-overview/housing-overview',
    mapbox: {
        style: 'bpd-acc/ciowo3k3f000udum18y8x7qd0',
        accessToken:
            'pk.eyJ1IjoiYnBkLWFjYyIsImEiOiJjaWlzbzBreXcwMzk5dTZrbnNlMDY1ZTFhIn0.N4JONLqHs9Bc67kJTikkXw',
    },
    metaTitle: ' | BPD.nl',
};

const test = () => {
    window.__ENVIRONMENT_DETAILS__ = {
        ...defaultProps,
    };
};

const mockData = { test };

export default mockData;
