import { defineElement, FC, onDidLoad, useElement, useRef } from '@atomify/hooks';
import { h } from '@atomify/jsx';
import { useClassname } from '@atomify/kit';
import { ScrollCallback, useScrollPosition, useStylesheet } from '@source/utilities/hooks';
import { inviewObserver } from '@source/utilities/inview';

import style from './scroll-indicator.scss';

const SCROLL_ZERO_ACTIVE_CLASS = 'scroll-indicator__counter-zero--is-hidden';

const ScrollIndicator: FC = () => {
    const scrollIndicator = useRef<HTMLSpanElement | null>(null);
    const scrollZero = useRef<HTMLSpanElement | null>(null);
    const scrollItem = useRef<HTMLSpanElement | null>(null);
    const main = useElement<HTMLElement>('main', { root: document });
    const scrollZeroClasses = useClassname({
        'scroll-indicator__counter-zero': true,
    });

    // Add element styles
    useStylesheet(style);

    // Get scroll position and update the percentage of the progress bar
    useScrollPosition(scrollPositionHandler, true);

    // Wait until component is loaded
    onDidLoad(() => {
        if (main.current) {
            const children: Element[] = Array.from(main.current.children);
            generateCounterItems(children.length);
            initObserver(children);
        }
    });

    /**
     * Generate items based upon the direct children of the <main> element
     * @param {number} itemLength
     */
    function generateCounterItems(itemLength: number) {
        const items = [...Array(itemLength)];
        const container = scrollItem.current;

        items.map((_, index) => {
            if (container) {
                container.appendChild(<div>{index + 1}</div>);
            }
        });
    }

    /**
     * Initialize intersection observer
     * @param {Element[]} children
     */
    function initObserver(children: Element[]) {
        const inviewOptions = {
            root: null,
            // threshold: 0.5,
            rootMargin: '0px 0px -50% 0px',
        };

        children.forEach((child, index) => {
            inviewObserver.observe(
                child,
                inView => {
                    if (inView) setCurrentItemPosition(children.length, index);
                },
                inviewOptions,
            );
        });
    }
    /**
     * Set the current active section
     * @param {number} itemLength
     * @param {number} currentIndex
     */
    function setCurrentItemPosition(itemLength: number, currentIndex: number) {
        const scrollPercentage = (currentIndex / itemLength) * 100;
        const scrollItemElement = scrollItem.current;

        if (currentIndex >= 9 && !scrollZeroClasses.contains(SCROLL_ZERO_ACTIVE_CLASS)) {
            scrollZeroClasses.add(SCROLL_ZERO_ACTIVE_CLASS);
        } else if (currentIndex < 9 && scrollZeroClasses.contains(SCROLL_ZERO_ACTIVE_CLASS)) {
            scrollZeroClasses.remove(SCROLL_ZERO_ACTIVE_CLASS);
        }

        // Set current item based up on the current index and the amount of items
        if (scrollItemElement) {
            scrollItemElement.style.transform = `translateY(-${scrollPercentage}%)`;
        }
    }

    /**
     * Set the current scroll percentage
     * @param {ScrollCallback} { currentPosition }
     */
    function scrollPositionHandler({ currentPosition }: ScrollCallback) {
        const documentHeight =
            document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollStep = currentPosition.y / documentHeight;

        if (scrollIndicator.current) {
            scrollIndicator.current.style.transform = `scaleY(${scrollStep})`;
        }
    }

    return (
        <div class="scroll-indicator__wrapper">
            <div class="scroll-indicator__counter">
                <div class={scrollZeroClasses} ref={scrollZero}>
                    0
                </div>
                <div class="scroll-indicator__counter-item" ref={scrollItem}></div>
            </div>

            <div class="scroll-indicator__background">
                <div class="scroll-indicator__progress" ref={scrollIndicator}></div>
            </div>
        </div>
    );
};

defineElement('scroll-indicator', ScrollIndicator, { useShadowDom: true });
