import {
    defineElement,
    FC,
    onDidLoad,
    useBindMethod,
    useElement,
    useEvent,
    useListen,
} from '@atomify/hooks';
import { useStylesheet } from '@source/utilities/hooks';

import { LOAD_MORE_ELEMENTS } from './load-more-hook';
import style from './load-more.scss';

export interface LoadMore {
    setLoading: (state: boolean) => void;
    updatePage: (page?: number) => void;
    getPageAmount: () => number;
    hide: () => void;
}

export interface LoadMoreEvent {
    id: string | null;
}

const LOAD_MORE_LOADING = 'load-more--is-loading';
const LOAD_MORE_HIDDEN_CLASS = 'load-more--is-hidden';

// Component
export const LoadMore: FC<LoadMore> = ({ element }) => {
    const anchor = useElement<HTMLAnchorElement>('a');
    const event = useEvent<LoadMoreEvent>({
        eventName: 'load-more',
    });

    useStylesheet(style);

    useBindMethod('setLoading', (state: boolean) => {
        const action = state ? 'add' : 'remove';
        element.classList[action](LOAD_MORE_LOADING);
    });

    useBindMethod('updatePage', pageNumber => {
        const anchorElement = anchor.current;
        if (anchorElement) {
            const { pagePrefix } = getElementOptions();
            anchorElement.href = `?${pagePrefix}=${pageNumber}`;
        }
    });

    useBindMethod('hide', () => {
        element.classList.add(LOAD_MORE_HIDDEN_CLASS);
    });

    // Returns page amount set in the href
    useBindMethod('getPageAmount', () => {
        const anchorElement = anchor.current;
        const pagePrefix = getPagePrefix();

        if (!anchorElement) return 0;

        // Get search params from href
        const hrefSearch = anchorElement.getAttribute('href')!.split('?')[1];

        if (!hrefSearch) return 0;

        const hrefParams = new URLSearchParams(hrefSearch);
        const hrefPage = hrefParams.get(pagePrefix);

        return hrefPage ? parseInt(hrefPage) : 0;
    });

    onDidLoad(() => {
        const { id, element, pagePrefix, maxPageAmount } = getElementOptions();

        if (!id) return;

        LOAD_MORE_ELEMENTS.set(id, { element, id, pagePrefix, maxPageAmount });
    });

    useListen(anchor, 'click', (e: MouseEvent) => {
        e.preventDefault();

        const { id } = getElementOptions();

        event.emit({ id });
    });

    function getPagePrefix() {
        return element.dataset.pagePrefix ? element.dataset.pagePrefix : 'p';
    }

    function getElementOptions() {
        const pagePrefix = getPagePrefix();
        const id = element.id || null;
        const maxPageAmount = element.dataset.maxPageAmount
            ? parseInt(element.dataset.maxPageAmount)
            : 1;

        return {
            pagePrefix,
            maxPageAmount,
            id,
            element,
        };
    }
};

defineElement('load-more', LoadMore);
