import { useElement, useRef } from '@atomify/hooks';
import { MutableRefObject } from '@atomify/hooks/dist/hooks';

type LazyResult = HTMLElement;

interface UseLazyProps<T> {
    fetcher: () => Promise<T>;
    loadingResult: () => LazyResult;
    errorResult: () => LazyResult;
    successResult: (data?: T) => LazyResult;
}

type LazyReturn<T> = [
    () => LazyResult,
    () => Promise<void>,
    MutableRefObject<T | null>,
    MutableRefObject<string>,
];

export const LAZY_HOOKS = {
    LAZY_LOADING_HOOK: 'data-lazy-loading',
    LAZY_ERROR_HOOK: 'data-lazy-error',
    LAZY_SUCCESS_HOOK: 'data-lazy-success',
};

export const LAZY_STATES = {
    ERROR: 'error',
    LOADING: 'loading',
    SUCCESS: 'success',
};

export const useLazy = <T = any>({
    fetcher,
    loadingResult,
    errorResult,
    successResult,
}: UseLazyProps<T>): LazyReturn<T> => {
    const LazyResult = useRef<() => LazyResult>(loadingResult);
    const lazyLoadingResult = useElement<HTMLElement>(`[${LAZY_HOOKS.LAZY_LOADING_HOOK}]`);
    const lazyErrorResult = useElement<HTMLElement>(`${[LAZY_HOOKS.LAZY_ERROR_HOOK]}`);
    const lazySuccessResult = useElement<HTMLElement>(`${[LAZY_HOOKS.LAZY_SUCCESS_HOOK]}`);
    const response = useRef<T | null>(null);
    const state = useRef<string>(LAZY_STATES.LOADING);

    async function initFetcher() {
        const activeChild =
            lazyLoadingResult.current ||
            lazySuccessResult.current ||
            lazyErrorResult.current ||
            false;

        if (state.current !== LAZY_STATES.LOADING) {
            state.current = LAZY_STATES.LOADING;
        }

        try {
            const data = await fetcher();

            response.current = data;

            if (activeChild && activeChild.parentNode) {
                activeChild.parentNode.replaceChild(successResult(data), activeChild);
            }

            state.current = LAZY_STATES.SUCCESS;
        } catch (error) {
            if (activeChild && activeChild.parentNode) {
                activeChild.parentNode.replaceChild(errorResult(), activeChild);
            }

            state.current = LAZY_STATES.ERROR;
        }
    }

    return [LazyResult.current, initFetcher, response, state];
};
