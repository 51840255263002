import { h } from '@atomify/jsx';

import { Image, ImageInterface } from '../../image';

interface CheckboxProps {
    componentType?: 'checkbox' | 'upsell' | null;
    validationTypes?: string | null;
    validationRegex?: string | null;
    validationErrorMessage?: string | null;
    requiredErrorMessage?: string | null;
    value?: any;
    horizontal?: boolean;
    classes?: string;
    disabled?: boolean;
    name: string;
    id: string;
    required?: boolean;
    checked?: boolean;
    hook?: string;
    attr?: string[];
    label: string;
    error?: string;
    srOnly?: boolean;
    description?: string | null;
    displayName?: string | null;
    hideError?: boolean;
    image?: ImageInterface | null;
}

export const CheckboxElement = ({
    componentType,
    validationTypes,
    validationRegex,
    validationErrorMessage,
    requiredErrorMessage,
    value,
    horizontal,
    classes,
    disabled,
    name,
    id,
    required,
    checked,
    hook,
    attr,
    label,
    description,
    error,
    srOnly,
    displayName,
    image,
    hideError = false,
}: CheckboxProps) => {
    const inputClasses = {
        ['c-checkbox']: true,
        ['form__item']: true,
        ['checkbox--horizontal']: horizontal,
        ['checkbox--upsell']: componentType === 'upsell',
        ['form__item--disabled']: disabled,
    };
    return (
        <bpd-input
            validation-types={validationTypes}
            validation-regex={validationRegex}
            validation-error-message={validationErrorMessage}
            required-error-message={requiredErrorMessage}
            bindValue={value}
            class={classes}
            displayName={displayName}>
            <div class={inputClasses}>
                <div class="checkbox__wrapper">
                    <input
                        class="checkbox__input u-sr-only"
                        type="checkbox"
                        name={name}
                        id={id}
                        required={required}
                        disabled={disabled}
                        checked={checked}
                        value={value}
                        label-associated={label}
                        {...{ [`js-hook-${hook ? hook : ''}`]: !!hook, ...attr }}
                    />

                    <label class="checkbox__label" for={id}>
                        {image ? <Image {...image} mode={'none'} /> : null}
                        {description ? (
                            <div class="label__container">
                                <span class={{ 'u-sr-only': srOnly }}>{label}</span>
                                <span>{description}</span>
                            </div>
                        ) : (
                            <span class={{ 'u-sr-only': srOnly }}>{label}</span>
                        )}
                        <span class="checkbox__focus-helper"></span>
                    </label>
                </div>
                {!hideError && (
                    <div class="form__item-error" role="alert">
                        {error}
                    </div>
                )}
            </div>
        </bpd-input>
    );
};
