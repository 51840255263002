import { h } from '@atomify/jsx';
import { Button, ButtonA } from '@atoms/button/button';
import { Container } from '@atoms/container/container';
import { Row } from '@atoms/row/row';
import { Col } from '@source/components/atoms/col/col';

const CookieBar = ({
    title,
    text,
    settingsLabel,
    settingsUrl,
    acceptLabel,
    onAccept,
}: {
    title: string;
    text: string;
    settingsLabel: string;
    settingsUrl: string;
    acceptLabel: string;
    onAccept: Function;
}) => {
    return (
        <div class="c-cookie-bar" tabindex="-1" aria-label="Cookiebar" data-policy-version="1">
            <Container>
                <div class="cookie-bar__content">
                    <Row>
                        <Col cols={[10, , 6]}>
                            <h2 class="cookie-bar__title ">{title}</h2>
                            <p class="cookie-bar__text">{text}</p>
                        </Col>

                        <Col cols={[10, , 3]} pre={[, , 1]}>
                            <div class="cookie-bar__button-holder">
                                <Button
                                    classes="cookie-bar__button"
                                    attr={{ tabindex: '-1' }}
                                    type="button"
                                    label={acceptLabel}
                                    onClick={() => onAccept()}
                                />

                                <ButtonA
                                    classes="cookie-bar__button"
                                    attr={{ tabindex: '-1' }}
                                    type="button"
                                    label={settingsLabel}
                                    url={settingsUrl}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default CookieBar;
