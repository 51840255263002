import { generateQuickGuid } from '@atomify/hooks/dist/utilities';
import { Fragment, h } from '@atomify/jsx';
import { Button, ButtonA } from '@source/components/atoms/button/button';
import { CollapsibleContent } from '@source/components/atoms/content-collapse/content-collapse';
import {
    CheckboxElement,
    CheckboxList,
    FormElement,
    FormHeading,
    FormRichText,
    FormRowElement,
    InputElement,
    RadioElement,
    SelectElement,
    TagElement,
    TextareaElement,
} from '@source/components/atoms/form-elements';
import { FormByIdResponse, PageComponent } from '@source/services/forms';

function getFormElement(type: string, component: PageComponent): HTMLElement | null {
    const {
        fieldName = '',
        label,
        validationRegex,
        validationErrorMessage,
        defaultValue,
        required,
        requiredErrorMessage,
        options = [],
        heading,
        body,
        maxLengthErrorMessage,
        maxLength,
        url,
        imageUrl,
        description,
    } = component;

    switch (type) {
        case 'textbox':
            return (
                <InputElement
                    name={fieldName}
                    id={generateQuickGuid()}
                    label={label || ''}
                    labelAsPlaceholder={!!label}
                    validationRegex={validationRegex}
                    validationErrorMessage={validationErrorMessage}
                    value={defaultValue}
                    requiredErrorMessage={requiredErrorMessage}
                    required={required}
                    displayName={fieldName}
                    type={'text'}
                />
            );
        case 'hidden':
            return (
                <InputElement
                    name={fieldName}
                    id={generateQuickGuid()}
                    value={defaultValue}
                    label={label || ''}
                    displayName={fieldName}
                    type={'hidden'}
                />
            );
        case 'tag':
            return (
                <TagElement
                    label={label || ''}
                    name={fieldName}
                    id={generateQuickGuid()}
                    validationRegex={validationRegex}
                    validationErrorMessage={validationErrorMessage}
                    value={defaultValue}
                    requiredErrorMessage={requiredErrorMessage}
                    required={required}
                    displayName={fieldName}
                />
            );
        case 'checkboxlist':
            return (
                <CheckboxList
                    title={label}
                    required={required}
                    requiredErrorMessage={requiredErrorMessage}>
                    {options &&
                        options.map(option => (
                            <CheckboxElement
                                label={option.label}
                                name={`${fieldName}[]`}
                                id={generateQuickGuid()}
                                value={option.value}
                                displayName={fieldName}
                                hideError={true}
                            />
                        ))}
                </CheckboxList>
            );

        case 'taglist':
            return (
                <CheckboxList
                    title={label}
                    required={required}
                    requiredErrorMessage={requiredErrorMessage}
                    isTagList={true}>
                    {options &&
                        options.map(option => (
                            <TagElement
                                label={option.label}
                                name={`${fieldName}[]`}
                                id={generateQuickGuid()}
                                value={option.value}
                                displayName={fieldName}
                                hideError={true}
                            />
                        ))}
                </CheckboxList>
            );
        case 'checkbox':
        case 'upsell':
            return (
                <CheckboxElement
                    label={label || ''}
                    name={fieldName}
                    id={generateQuickGuid()}
                    validationRegex={validationRegex}
                    validationErrorMessage={validationErrorMessage}
                    value={defaultValue}
                    required={required}
                    requiredErrorMessage={requiredErrorMessage}
                    displayName={fieldName}
                    componentType={type}
                    description={description}
                    {...(type === 'upsell' && {
                        image: { image: imageUrl ?? '', alt: '', preload: { height: 100 } },
                    })}
                />
            );
        case 'textarea':
            return (
                <TextareaElement
                    name={fieldName}
                    id={generateQuickGuid()}
                    label={label || ''}
                    labelAsPlaceholder={!!label}
                    validationRegex={validationRegex}
                    validationErrorMessage={validationErrorMessage}
                    value={defaultValue}
                    requiredErrorMessage={requiredErrorMessage}
                    required={required}
                    maxLength={maxLength}
                    maxLengthErrorMessage={maxLengthErrorMessage}
                    displayName={fieldName}
                />
            );
        case 'dropdown':
            const generatedListOptions = [...options, { label, value: '' }].map(option => {
                return {
                    label: option.label || '',
                    value: option.value,
                    default: label === option.label,
                };
            });
            return (
                <SelectElement
                    name={fieldName}
                    id={generateQuickGuid()}
                    label={label || ''}
                    labelAsPlaceholder={true}
                    validationRegex={validationRegex}
                    validationErrorMessage={validationErrorMessage}
                    value={defaultValue}
                    displayName={fieldName}
                    requiredErrorMessage={requiredErrorMessage}
                    required={required}
                    options={generatedListOptions}
                />
            );
        case 'link':
            return (
                <div class="section-form__link-wrapper">
                    <ButtonA label={label || ''} url={url || ''} classes="section-form__link" />
                </div>
            );
        case 'inlinerow':
            return (
                <FormRowElement>
                    {component.components &&
                        component.components.map(component => {
                            if (component.conditionalOn) {
                                return (<CollapsibleContent
                                    conditionalOn={component.conditionalOn}
                                    expanded={false}
                                    name={`collapsible ${component.fieldName}`}>
                                    {getFormElement(component.componentType, component)}
                                </CollapsibleContent>);
                            } else {
                                return getFormElement(component.componentType, component);
                            }
                        })
                    }
                </FormRowElement>
            );
        case 'radiolist':
            const generatedOptions = options.map(option => {
                return {
                    name: fieldName,
                    id: generateQuickGuid(),
                    required,
                    label: option.label || '',
                    value: option.value,
                    checked: option.value === defaultValue,
                };
            });
            return (
                <RadioElement
                    label={label}
                    horizontal={true}
                    validationRegex={validationRegex}
                    validationErrorMessage={validationErrorMessage}
                    value={defaultValue}
                    displayName={fieldName}
                    requiredErrorMessage={requiredErrorMessage}
                    options={generatedOptions}
                    required={required}
                />
            );
        case 'paragraph':
            return (
                <Fragment>
                    {heading && <FormHeading heading={heading} />}
                    {body && <FormRichText html={body} />}
                </Fragment>
            );
        default:
            return null;
    }
}

export const generateForm = (data: FormByIdResponse, id: string) => {
    const { pages = [], type = '' } = data;

    return (
        <Fragment>
            {pages.map((page, i) => (
                <FormElement
                    id={id}
                    type={type}
                    novalidate={true}
                    saveWhenComplete={page.saveWhenComplete}>
                    {page.heading && page.heading !== '' && <FormHeading heading={page.heading} />}
                    {page.components &&
                        page.components.map(component => {
                            if (component.conditionalOn) {
                                return (
                                    <CollapsibleContent
                                        conditionalOn={component.conditionalOn}
                                        expanded={false}
                                        name={`collapsible ${component.fieldName}`}>
                                        {getFormElement(component.componentType, component)}
                                    </CollapsibleContent>
                                );
                            } else {
                                return getFormElement(component.componentType, component);
                            }
                        })}
                    {page.submitButtonText && (
                        <Button
                            type="submit"
                            label={page.submitButtonText}
                            hook="submit"
                            classes="c-button--loading section-form__button"
                            attr={{
                                'data-ga': `step-${i + 1}`,
                            }}
                        />
                    )}
                </FormElement>
            ))}
        </Fragment>
    );
};
