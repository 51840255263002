import { h } from '@atomify/jsx';

interface CollapsibleContentInterface {
    children?: Element;
    classes?: string;
    name: string;
    expanded: boolean;
    collapsedBreakpoint?: string;
    conditionalOn?: string;
}

interface CollapsibleContentButtonInterface {
    children?: Element;
    classes?: string;
    name: string;
    expanded?: boolean;
    label: string;
    collapsedLabel?: string;
    expandedLabel?: string;
}

export const CollapsibleContent = ({
    children,
    classes,
    name,
    expanded,
    collapsedBreakpoint,
    conditionalOn
}: CollapsibleContentInterface) => {
    return (
        <bpd-content-collapse
            class={classes || ''}
            id={`${name}-controls`}
            aria-labelledby={`${name}-labelledby`}
            aria-hidden={!expanded}
            conditional-on={conditionalOn}
            data-collapsed-breakpoint={collapsedBreakpoint}>
            <div class="content-collapse__content">{children}</div>
        </bpd-content-collapse>
    );
};

export const CollapsibleContentToggle = ({
    children,
    classes,
    name,
    expanded,
    collapsedLabel,
    expandedLabel,
    label,
}: CollapsibleContentButtonInterface) => (
    <button
        class={`content-collapse__button ${classes ? classes : ''}`.trim()}
        id={`${name}-labelledby`}
        aria-controls={`${name}-controls`}
        aria-expanded={expanded || false}
        data-collapsed-label={collapsedLabel}
        data-expanded-label={expandedLabel}
        label={label}
        type="button">
        {children}
    </button>
);
