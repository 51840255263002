import { Component, Listen, Prop, Query, Watch } from '@atomify/core';
import { h } from '@atomify/jsx';
import { elements } from '@source/utilities/dom';
import { addModal, closeModal, modalStore } from '@store/modals';

import style from './modal.scss';

const MODAL_HOOK = '[js-hook-modal]';
const MODAL_VISIBLE_CLASS = 'modal--is-showing';
const MODAL_HTML_CLASS = 'no--scroll';
const IS_LOADED_CLASS = 'is--loaded';

export interface ModalInterface extends HTMLElement {
    openModal: Function;
    closeModal: Function;
}

@Component({
    tag: 'bpd-modal',
    shadow: true,
    style,
})
export class Modal extends HTMLElement {
    @Prop({ reflectToAttribute: true }) closeDisabled: boolean = false;
    @Prop({ reflectToAttribute: true, type: 'Boolean' }) active: boolean = false;

    @Query(MODAL_HOOK) modal: HTMLDivElement;

    @Watch('active')
    activeStateChanged(newValue: boolean) {
        const action = newValue ? 'add' : 'remove';
        this.modal.classList[action](MODAL_VISIBLE_CLASS);
        elements.html.classList[action](MODAL_HTML_CLASS);
    }

    @Listen('keydown', { target: document })
    keydownTrigger(e: KeyboardEvent) {
        if (e.keyCode === 27) closeModal(this.id);
    }

    componentDidLoad() {
        // Open modal based upon active state
        this.activeStateChanged(this.active);

        if (this.id) {
            // Add modal to the store based upon the modal id
            addModal(this.id);

            // Subscribe to the store to know when a modal is changed.
            modalStore.subscribe(({ modals }) => {
                const { active } = modals[this.id];
                this.active = active;
            });
        }

        this.classList.add(IS_LOADED_CLASS);
    }

    render() {
        return (
            <div class="c-modal" js-hook-modal>
                <div class="modal__container">
                    <div class="modal__content" role="dialog">
                        <slot />
                    </div>
                </div>
            </div>
        );
    }
}
