const ST_DURATION = 500;
const ST_OFFSET = 50;

interface ScrollOptions {
    offset?: number;
    duration?: number;
    scrollElement?: HTMLElement;
    delay?: number;
}

export const useScrollTo = (options: ScrollOptions = {}) => {
    function to(target: HTMLElement) {
        const scrollConfig = {
            position: target.getBoundingClientRect(),
            offset: options.offset || ST_OFFSET,
            duration: options.duration || ST_DURATION,
            scrollElement: options.scrollElement || null,
            delay: options.delay || 0,
        };

        return scrollTo(scrollConfig);
    }

    return to;
};

/**
 * Scrolls to an element
 * @param {({
 *     position: DOMRect;
 *     offset: number;
 *     duration: number;
 *     scrollElement: HTMLElement | null;
 * })} {
 *     position,
 *     offset,
 *     duration,
 *     scrollElement,
 * }
 * @returns
 */
function scrollTo({
    position,
    offset,
    duration,
    scrollElement,
    delay,
}: {
    position: DOMRect;
    offset: number;
    duration: number;
    scrollElement: HTMLElement | null;
    delay: number;
}) {
    return new Promise(resolve => {
        const scrollPosition = window.scrollY === undefined ? window.pageYOffset : window.scrollY;
        const to = parseInt((position.top + scrollPosition - offset).toFixed(0), 10);
        const start = scrollElement
            ? scrollElement.scrollTop
            : Math.max(document.body.scrollTop, document.documentElement.scrollTop);
        const change = to - start;
        let currentTime = 0;
        const increment = 10;
        const direction = to > start ? 1 : 0;

        let timeout: any;

        const animate = () => {
            currentTime += increment;
            const val = parseInt(
                easeInOutQuad(currentTime, start, change, duration).toFixed(0),
                10,
            );

            if (scrollElement) {
                scrollElement.scrollTop = val;
            } else {
                document.body.scrollTop = val;
                document.documentElement.scrollTop = val;
            }

            if ((val >= to && direction === 1) || (val <= to && direction === 0)) {
                resolve();
            } else {
                requestAnimationFrame(animate);
            }
        };

        if (delay > 0) {
            timeout = setTimeout(() => {
                animate();
                clearTimeout(timeout);
            }, delay);
        } else {
            animate();
        }
    });
}

function easeInOutQuad(t: number, b: number, c: number, d: number) {
    t /= d / 2;
    if (t < 1) {
        return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
}
