import { h } from '@atomify/jsx';

interface TagProps {
    validationTypes?: string | null;
    validationRegex?: string | null;
    validationErrorMessage?: string | null;
    requiredErrorMessage?: string | null;
    value?: any;
    horizontal?: boolean;
    classes?: string;
    disabled?: boolean;
    name: string;
    id: string;
    required?: boolean;
    checked?: boolean;
    hook?: string;
    attr?: string[];
    label: string;
    error?: string;
    displayName?: string | null;
    hideError?: boolean;
}

export const TagElement = ({
    validationTypes,
    validationRegex,
    validationErrorMessage,
    requiredErrorMessage,
    value,
    horizontal,
    classes,
    disabled,
    name,
    id,
    required,
    checked,
    hook,
    attr,
    label,
    error,
    displayName,
    hideError = false,
}: TagProps) => {
    const inputClasses = {
        ['c-tag']: true,
        ['form__item']: true,
        'tag--horizontal': horizontal,
        ['form__item--disabled']: disabled,
    };
    return (
        <bpd-input
            validation-types={validationTypes}
            validation-regex={validationRegex}
            validation-error-message={validationErrorMessage}
            required-error-message={requiredErrorMessage}
            bindValue={value}
            class={classes}
            displayName={displayName}>
            <div class={inputClasses}>
                <div class="tag__wrapper">
                    <input
                        class="tag__input u-sr-only"
                        type="checkbox"
                        name={name}
                        id={id}
                        required={required}
                        disabled={disabled}
                        checked={checked}
                        value={value}
                        {...{ [`js-hook-${hook ? hook : ''}`]: !!hook, ...attr }}
                    />

                    <label class="tag__label" for={id}>
                        <span>{label}</span>
                        <span class="tag__focus-helper"></span>
                    </label>
                </div>
                {!hideError && (
                    <div class="form__item-error" role="alert">
                        {error}
                    </div>
                )}
            </div>
        </bpd-input>
    );
};
