import { Component, Listen, Prop, Query, QueryAll } from '@atomify/core';

const IS_HIDDEN_CLASS = 'theme-block__background-image--hidden';
@Component({
    tag: 'bpd-theme-block',
})
export class SectionTheme extends HTMLElement {
    @Prop() defaultTheme: string;
    @Query('[js-hook-theme-block]') blockElement: HTMLElement;
    @Query('[js-hook-theme-block-background]') backgroundContainer: HTMLElement;
    @QueryAll('[js-hook-theme-item]') themeItem: HTMLElement;

    defaultBackgroundImage: HTMLImageElement | null;
    themeBackgroundImage: HTMLImageElement | null;
    selectedTheme: string | null;

    @Listen('mouseenter', { target: 'themeItem' })
    handleMouseEnter(event: MouseEvent | null) {
        const target = event?.target as HTMLElement;

        this.selectedTheme = target.getAttribute('js-hook-theme-item');
        this.themeBackgroundImage = this.backgroundContainer.querySelector(
            `[js-hook-theme-block-background-image-theme='${this.selectedTheme}']`,
        );
        this.defaultBackgroundImage?.classList.add(IS_HIDDEN_CLASS);
        this.themeBackgroundImage?.classList.remove(IS_HIDDEN_CLASS);

        if (this.selectedTheme !== this.defaultTheme) {
            this.blockElement.classList.add(`u-theme-${this.selectedTheme}`);
            this.blockElement.classList.remove(`u-theme-${this.defaultTheme}`);
        }
    }

    @Listen('mouseleave', { target: 'themeItem' })
    handleMouseLeave() {
        this.defaultBackgroundImage?.classList.remove(IS_HIDDEN_CLASS);
        this.themeBackgroundImage?.classList.add(IS_HIDDEN_CLASS);

        if (this.selectedTheme !== this.defaultTheme) {
            this.blockElement.classList.remove(`u-theme-${this.selectedTheme}`);
            this.blockElement.classList.add(`u-theme-${this.defaultTheme}`);
        }
    }

    componentDidLoad() {
        this.defaultBackgroundImage = this.backgroundContainer.querySelector(
            `[js-hook-theme-block-background-image-default='${this.defaultTheme}']`,
        );
    }
}
