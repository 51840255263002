import { Fragment, h } from '@atomify/jsx';

export interface SelectElementProps {
    validationTypes?: string | null;
    validationRegex?: string | null;
    validationErrorMessage?: string | null;
    requiredErrorMessage?: string | null;
    value?: any;
    classes?: string;
    variant?: 'bordered' | 'default';
    labelAsPlaceholder?: boolean;
    error?: string;
    disabled?: boolean;
    required?: boolean;
    name: string;
    id: string;
    hook?: string;
    attr?: string[];
    label: string;
    sronly?: boolean;
    displayName?: string | null;
    bpdInputHook?: string;
    options: Array<{
        // label: string;
        default?: boolean;
        value: any;
        label: string | Text;
    }>;
    mobileEnabled?: boolean;
}

export const SelectElement = ({
    validationTypes,
    validationRegex,
    validationErrorMessage,
    requiredErrorMessage,
    value,
    labelAsPlaceholder,
    disabled,
    classes,
    variant,
    error,
    name,
    id,
    hook,
    attr,
    options,
    sronly,
    label,
    required,
    displayName,
    bpdInputHook,
    mobileEnabled,
}: SelectElementProps) => {
    const inputClasses: { [key: string]: boolean | undefined } = {
        ['c-select']: true,
        ['select--bordered']: variant === 'bordered',
        ['select--mobile-enabled']: mobileEnabled,
        ['form__item']: true,
        ['form__item--disabled']: disabled,
        ['form__item--lap']: labelAsPlaceholder,
    };

    if (classes) inputClasses[classes] = true;

    return (
        <bpd-select class={inputClasses} mobile-enabled={mobileEnabled}>
            <bpd-input
                validation-types={validationTypes}
                validation-regex={validationRegex}
                validation-error-message={validationErrorMessage}
                required-error-message={requiredErrorMessage}
                bindValue={value}
                displayName={displayName}
                {...{ [`js-hook-${bpdInputHook ? bpdInputHook : ''}`]: !!bpdInputHook }}>
                <div class="select__wrapper">
                    <label class={{ ['select__label']: true, ['u-sr-only']: sronly }} for={id}>
                        {label}
                    </label>
                    <select
                        class="select__input"
                        name={name}
                        id={id}
                        required={required}
                        disabled={disabled}
                        {...{ [`js-hook-${hook ? hook : ''}`]: !!hook, ...attr }}>
                        {options &&
                            options.map(item => (
                                <Fragment>
                                    {item.default ? (
                                        <option
                                            hidden={item.default}
                                            selected={item.default}
                                            value></option>
                                    ) : (
                                        <option value={item.value}>{item.label}</option>
                                    )}
                                </Fragment>
                            ))}
                    </select>
                    <span class="focus-helper"></span>
                </div>

                <ul class="select__dropdown" js-select-dropdown>
                    {options &&
                        options
                            .filter(item => !item.default)
                            .map(item => (
                                <li class="select__dropdown-item">
                                    <button
                                        class="select__dropdown-button"
                                        js-hook-select-item
                                        data-value={item.value}>
                                        {item.label}
                                    </button>
                                </li>
                            ))}
                </ul>

                <div class="form__item-error" role="alert">
                    {error}
                </div>
            </bpd-input>
        </bpd-select>
    );
};
