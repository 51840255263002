import { useListen, useRef } from '@atomify/hooks';

import { LoadMore, LoadMoreEvent } from './load-more';

interface LoadMoreOptions {
    pagePrefix: string;
    id: string | null;
    element: LoadMore;
    maxPageAmount: number;
}

type OnUpdatCallback = (arg: { element: LoadMore; page: number; hasLoadMore: boolean }) => void;

export const LOAD_MORE_ELEMENTS = new Map<string, LoadMoreOptions>();

// Custom hook
export const useLoadMore = (
    id: string,
): [(state: boolean) => void, (cb: OnUpdatCallback) => void, any] => {
    const currentElement = useRef<LoadMore>();

    let callback: OnUpdatCallback;

    useListen(document, 'load-more', (e: CustomEvent) => {
        const data: LoadMoreEvent = e.detail;

        if (data.id === id && LOAD_MORE_ELEMENTS.has(id)) {
            const options = LOAD_MORE_ELEMENTS.get(id);

            if (options) {
                setCurrentElement(options.element);
                notifyCallback(options);
            }
        }
    });
    /**
     * Set current element.
     * @param {LoadMore} element
     */
    function setCurrentElement(element: LoadMore) {
        currentElement.current = element;
    }

    /**
     * Set the loading state of the current clicked load more button
     * @param {boolean} value
     */
    function setLoadingState(value: boolean) {
        // set loading state
        if (currentElement.current) {
            currentElement.current.setLoading(value);
        }
    }

    /**
     * Set the on update callback that gets triggered everytime the load more button is clicked
     * @param {OnUpdatCallback} cb
     */
    function onUpdate(cb: OnUpdatCallback) {
        if (!callback) callback = cb;
    }

    function notifyCallback(options: LoadMoreOptions) {
        setLoadingState(true);

        if (callback) {
            const { element, maxPageAmount } = options;
            const page = element.getPageAmount();
            const hasLoadMore = page !== maxPageAmount;

            if (hasLoadMore) {
                element.updatePage(page + 1);
            }
            callback({ element, page, hasLoadMore });
        }
    }

    return [setLoadingState, onUpdate, currentElement];
};
