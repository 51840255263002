import {
    defineElement,
    FC,
    onDidLoad,
    useElement,
    useElements,
    useEvent,
    useListen,
    useProp,
} from '@atomify/hooks';
import { ContentCollapseInterface } from '@atoms/content-collapse';

import { BPDInput } from '../utilities/input';

export interface BPDForm extends HTMLElement {
    completed?: boolean;
    saveWhenComplete?: boolean;
    type?: string;
}

const BPDFormElement: FC<BPDForm> = ({ element }) => {
    useProp<boolean>('completed', false);
    useProp<boolean>('saveWhenComplete', false);
    useProp<string>('type', '');

    const inputs = useElements<BPDInput[]>('bpd-input, bpd-radio, bpd-checkbox-list');
    const elements = useElements<HTMLElement[]>('select, input, textarea');
    const form = useElement<HTMLFormElement>('form');
    const contentCollapses = useElements<ContentCollapseInterface[]>('bpd-content-collapse');

    const serializedForm = useEvent({ eventName: 'serialize-form' });

    onDidLoad(initConditionalFields);

    useListen(form, 'submit', (e: Event) => {
        if (e) e.preventDefault();

        const { valid } = validate();

        if (valid) {
            serializeForm();
        }
    });

    function checkValidity() {
        return Array.from(inputs.current).filter(input => input && !input.valid).length === 0;
    }

    function validate(): { valid: boolean } {
        if (!form.current) return { valid: false };

        let valid = checkValidity();

        Array.from(inputs.current).forEach(input => {
            if (input && input.validate) {
                valid = input.validate() && valid;
            }
        });

        return {
            valid,
        };
    }

    function serializeForm() {
        const data: { [key: string]: any } = {};
        const listItems: { [key: string]: Array<any> } = {};

        Array.from(elements.current).map((element: HTMLInputElement) => {
            const isRadioButton = element.type === 'radio';
            const isEmptyCheckboxOrRadio =
                (isRadioButton || element.type === 'checkbox') && !element.checked;

            const elementName = element.name;
            const isCheckboxList = elementName.includes('[]');
            const inputValue = isEmptyCheckboxOrRadio ? '' : element.value;

            if (isRadioButton) {
                // Check if radio button is already there (because radio has two inputs) and the incoming radio has a value update it.
                // Or update the data when there is no previous radio available.
                if ((data[elementName] && inputValue) || !data[elementName]) {
                    data[elementName] = inputValue;
                }
                return;
            } else if (!isCheckboxList) {
                data[elementName] = inputValue;
                return;
            } else {
                const inputName = elementName.replace('[]', '');
                if (!listItems[inputName]) listItems[inputName] = [];
                if (inputValue) listItems[inputName].push(inputValue);
                const values = listItems[inputName];
                data[inputName] = values.join(values.length > 1 ? ',' : '');
                return;
            }
        });

        serializedForm.emit({ fields: data, form: element });

        return data;
    }

    function handleConditionalField(field: HTMLInputElement) {
        if (!contentCollapses.current) return;
        const triggerID = field.getAttribute('name');
        const collapsables = Array.from(contentCollapses.current).filter(
            entry => entry?.getAttribute(`conditional-on`) === triggerID,
        );
        collapsables.forEach(element => {
            if (element?.getAttribute('expanded')) {
                element.removeAttribute('expanded');
            } else {
                element?.setAttribute('expanded', 'true');
            }

            const fields = element?.querySelectorAll<BPDInput>('bpd-input');
            if (fields) {
                handleConditionalRequired(fields);
            }
        });
    }

    function initConditionalFields() {
        const conditionalFields = element.querySelectorAll<BPDInput>('[conditional-on]');
        handleConditionalRequired(conditionalFields);
        conditionalFields.forEach(field => {
            const dependentField = element.querySelector<HTMLInputElement>(
                `[name="${field?.getAttribute('conditional-on')}"]`,
            );
            if (dependentField) {
                if (!dependentField.getAttribute('has-listener')) {
                    dependentField.setAttribute('has-listener', 'true');
                    dependentField.addEventListener('change', () =>
                        handleConditionalField(dependentField),
                    );
                }
            }
        });
    }

    function handleConditionalRequired(conditionalFields: NodeListOf<BPDInput>) {
        conditionalFields.forEach(conditionalField => {
            const requiredFields = conditionalField.querySelectorAll('[required]');
            if (requiredFields.length > 0) {
                toggleRequiredStates(requiredFields, false);
            } else {
                const disabledRequiredFields = conditionalField.querySelectorAll(
                    '[disabled-required]',
                );
                toggleRequiredStates(disabledRequiredFields, true);
            }
        });
    }

    function toggleRequiredStates(fields: NodeListOf<Element>, requireDisabled: boolean) {
        if (requireDisabled) {
            fields.forEach(field => {
                field.setAttribute('required', '');
                field.removeAttribute('disabled-required');
            });
        } else {
            fields.forEach(field => {
                field.setAttribute('disabled-required', '');
                field.removeAttribute('required');
                field.removeAttribute('invalid');
            });
        }
    }
};

BPDFormElement.props = {
    completed: {
        type: Boolean,
        reflectToAttr: true,
    },
    saveWhenComplete: {
        type: Boolean,
        reflectToAttr: true,
    },
    type: {
        type: String,
        reflectToAttr: true,
    },
};

defineElement('bpd-form', BPDFormElement);
