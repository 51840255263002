export type Selector = HTMLElement | HTMLCollection | NodeList | HTMLInputElement;
export type Options = {
    removeListener: boolean;
};
export type Callback = (state: boolean | null) => void;

const defaultOptions: Options = {
    removeListener: false,
};

const isNodeList = (target: any): boolean => NodeList.prototype.isPrototypeOf(target);
const isHTMLElement = (target: any): boolean => HTMLElement.prototype.isPrototypeOf(target);
const isHTMLCollection = (target: any): boolean => HTMLCollection.prototype.isPrototypeOf(target);

export function useClickOutside(
    selector: Selector,
    callback: Callback,
    options?: Options,
): EventListener {
    const theOptions = { ...defaultOptions, ...options };

    const listener = (event: Event) => {
        if (isNodeList(selector) || isHTMLCollection(selector)) {
            if (
                Array.from(selector as HTMLCollection | NodeList).some(selection =>
                    selection.contains(event.target as Node),
                )
            ) {
                return callback(false);
            }
        } else if (isHTMLElement(selector)) {
            if ((selector as HTMLElement).contains(event.target as Node)) {
                return callback(false);
            }
        } else {
            console.warn('Undefined type of', selector);
            return callback(null);
        }

        if (theOptions.removeListener) {
            document.removeEventListener('click', listener);
        }

        return callback(true);
    };

    document.addEventListener('click', listener);

    return listener;
}
