const EMAIL_ENCODED_ANCHOR_HOOK = 'js-hook-eml-encoded';
const EMAIL_ENCODED_CONTENT_HOOK = 'js-hook-eml-encoded-content';

function decode(encodedString: string) {
    // Holds the final output
    let email = '';

    // Convert the hex-encoded key into decimal
    const key = parseInt(encodedString.substr(0, 2), 16);

    // Loop through the remaining encoded characters in steps of 2
    for (let n = 2; n < encodedString.length; n += 2) {
        // Get the next pair of characters
        const charInHex = encodedString.substr(n, 2);

        // Convert hex to decimal
        const char = parseInt(charInHex, 16);

        // XOR the character with the key to get the original character
        const output = char ^ key;

        // Append the decoded character to the output
        email += String.fromCharCode(output);
    }
    return email;
}

/**
 * Performs decoding for all encoded emails coming from the backend
 *
 * @export
 */
export function emailDecoder() {
    const emailElements = document.querySelectorAll<HTMLAnchorElement>(
        `[${EMAIL_ENCODED_ANCHOR_HOOK}]`,
    );
    const emailContentElements = document.querySelectorAll<HTMLElement>(
        `[${EMAIL_ENCODED_CONTENT_HOOK}]`,
    );

    emailElements.forEach(e => {
        const encodedEmail = e.getAttribute(EMAIL_ENCODED_ANCHOR_HOOK);
        const email = decode(encodedEmail!);

        e.href = `mailto:${email}`;
    });

    emailContentElements.forEach(e => {
        const encodedEmail = e.getAttribute(EMAIL_ENCODED_CONTENT_HOOK);
        const email = decode(encodedEmail!);

        e.textContent = email;
    });
}
