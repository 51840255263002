import { Fragment, h } from '@atomify/jsx';

interface RadioProps {
    validationTypes?: string | null;
    validationRegex?: string | null;
    validationErrorMessage?: string | null;
    requiredErrorMessage?: string | null;
    value?: any;
    horizontal?: boolean;
    classes?: string;
    label?: string | null;
    error?: string;
    disabled?: boolean;
    displayName?: string | null;
    required?: boolean;
    options: Array<{
        disabled?: boolean;
        name: string;
        id: string;
        checked?: boolean;
        hook?: string;
        attr?: string[];
        label: string;
        value?: string;
    }>;
    singleOptionAllowed?: boolean;
}

export const RadioElement = ({
    validationTypes,
    validationRegex,
    validationErrorMessage,
    requiredErrorMessage,
    value,
    horizontal,
    disabled,
    classes,
    options,
    singleOptionAllowed,
    label,
    displayName,
    required,
    error,
}: RadioProps) => {
    const inputClasses = {
        ['c-radio']: true,
        ['form__item']: true,
        'radio--horizontal': horizontal,
        ['form__item--disabled']: disabled,
    };

    if (options.length === 0) return null;

    return (
        <bpd-radio
            validation-types={validationTypes}
            validation-regex={validationRegex}
            validation-error-message={validationErrorMessage}
            required-error-message={requiredErrorMessage}
            single-option-allowed={singleOptionAllowed}
            bindValue={value}
            class={classes}
            displayName={displayName}>
            <div class={inputClasses}>
                {label && <span class="form__label">{label}</span>}
                <div class="radio__wrapper">
                    {options.map(item => (
                        <Fragment>
                            <input
                                class="radio__input u-sr-only"
                                type="radio"
                                name={item.name}
                                id={item.id}
                                required={required}
                                disabled={disabled}
                                checked={item.checked}
                                value={item.value}
                                label-associated={item.label}
                                {...{
                                    [`js-hook-${item.hook ? item.hook : ''}`]: !!item.hook,
                                    ...item.attr,
                                }}
                            />

                            <label class="radio__label" for={item.id}>
                                {item.label}
                                <span class="radio__focus-helper"></span>
                            </label>
                        </Fragment>
                    ))}
                </div>
                <div class="form__item-error" role="alert">
                    {error}
                </div>
            </div>
        </bpd-radio>
    );
};
