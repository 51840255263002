import { useListen, useRef, useWatch } from '@atomify/hooks';
interface UsingKeyBoardProps {
    defaultUsingKeyboard?: boolean;
    className?: string;
}

export const useUsingKeyboard = ({
    defaultUsingKeyboard = false,
    className = 'using-keyboard',
}: UsingKeyBoardProps) => {
    const usingKeyboard = useRef(defaultUsingKeyboard);

    useListen({ current: document.body }, 'mousedown', () => (usingKeyboard.current = false));
    useListen({ current: document.body }, 'keydown', () => (usingKeyboard.current = true));

    useWatch(() => {
        const action = usingKeyboard.current ? 'add' : 'remove';
        document.body.classList[action](className);
    });
};
