import { CMSClient } from './../api/index';

export interface FormByIdResponse {
    pages: Page[];
    type: string;
}

export interface Page {
    saveWhenComplete: boolean;
    components: PageComponent[];
    heading?: string;
    submitButtonText?: string;
}

export interface PageComponent {
    componentType: string;
    fieldName?: string;
    label?: null | string;
    options?: Array<{
        label: string;
        value: string;
    }>;
    components?: PageComponent[];
    placeholder?: null | string;
    defaultValue?: null | string;
    validationRegex?: null | string;
    validationErrorMessage?: null;
    required?: boolean;
    requiredErrorMessage?: null;
    heading?: string;
    body?: string;
    maxLengthErrorMessage?: null | string;
    maxLength?: null | number;
    url?: string;
    conditionalOn?: string;
    imageUrl?: string;
    description?: string;
}

export const getFormById = (id: string) => {
    return CMSClient.get<FormByIdResponse>(`/api/v1/forms/${id}/`);
};

export const postFormById = (id: string, values: { [key: string]: any }) => {
    return CMSClient.post(
        `/api/v1/forms/${id}/`,
        { values },
        {
            baseUrl: '',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
};
