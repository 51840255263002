import { isLocalhost } from '@source/utilities/sw';

import { HttpClient } from './http';

export const getPrefixedUrl = (path? : string) => {
    const domain = isLocalhost ? 'https://test-www.bpd.nl/' : `${window.location.origin}/` // TODO: This has to come out of env variable
    if (path && !path.startsWith('//')) {
        path = path.startsWith('/') ? path.substring(1) : path;
        return `${domain}${path}`;
    } else if (path && path.startsWith('//')) {
        return path;
    }

    return domain;
}

export const APIClient = new HttpClient({
    baseUrl: window.__ENVIRONMENT_DETAILS__.apiBaseUrl, // TODO: This has to come out of env variable
    headers: {
        'Content-Type': 'application/json',
    },
});

export const CMSClient = new HttpClient({
    baseUrl: getPrefixedUrl()
});
