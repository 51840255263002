import { Component, defineElement, FC, useProp, useRef } from '@atomify/hooks';
import { h } from '@atomify/jsx';
import { useInput, UseInputElement } from '@source/utilities/hooks';

import { validateCustomRegex } from '../utilities';

export const CheckboxList = (props: any) => {
    const wrapperClasses = props.isTagList
        ? 'checkbox-list__wrapper checkbox-list__wrapper--tag-list'
        : 'checkbox-list__wrapper';

    return (
        <bpd-checkbox-list
            class="c-checkbox-list form__item"
            required-error-message={props.requiredErrorMessage}
            required={props.required}>
            <span class="form__label">{props.title}</span>
            <div class={wrapperClasses}>{props.children}</div>
            <div class="form__item-error" role="alert"></div>
        </bpd-checkbox-list>
    );
};

export interface BPDCheckboxlist extends Component, UseInputElement {
    valid: boolean;
}

const CheckboxListElement: FC<BPDCheckboxlist> = ({ element }) => {
    const [, setIsValid] = useProp<boolean>('valid', false);
    const [requiredErrorMessage] = useProp<string | null>('requiredErrorMessage', null);
    const [required] = useProp<boolean>('required', false);
    const [hideErrors] = useProp<boolean>('hideErrors', false);

    const inputElement = useRef<HTMLInputElement | null>(null);
    const errors = useRef<string[]>([]);
    const errorMessage = useRef<string>('');

    const { inputs, errorHolder, inputValidate } = useInput({
        inputElements: 'input',
        errorTarget: '[role="alert"]',
        onValidate,
        onInputChange,
    });

    function onInputChange() {
        element.validate();
    }

    /**
     * Adds a validate function to the input component
     * It validates the component based upon validation types and the value.
     */
    function onValidate() {
        const inputElements = inputs.current;

        if (!inputElements && !required) return true;

        const disabled = [...inputElements].filter(input => input && input.disabled).length > 0;
        const checked = [...inputElements].filter(input => input && input.checked).length > 0;

        if (disabled) return true;

        const isValidElement = !required || (required && checked);
        const value = isValidElement;

        // Set errors
        errors.current = getErrors(value);

        const hasErrors = errors.current.length === 0;

        // Set is valid property
        setIsValid(hasErrors);

        // Set error message
        errorMessage.current = element.valid ? '' : errors.current[0];

        // Emit input validate event.
        inputValidate.emit({
            errorMessage: errorMessage.current,
            value,
            isValid: element.valid,
            input: inputElement.current!,
        });

        updateErrorValidState();

        return element.valid;
    }

    /**
     * Sets and removes errors from the error holder based upon the active error message
     */
    function updateErrorValidState() {
        const inputElements = inputs.current;
        const errorContainer = errorHolder.current;

        if (!inputElements || !errorContainer) return;

        if (errors.current.length > 0) {
            element.setAttribute('invalid', '');

            [...inputElements].forEach(input => input && input.setAttribute('invalid', ''));

            element.removeAttribute('valid');

            if (!hideErrors) errorContainer.textContent = errorMessage.current;
        }

        if (element.valid) {
            element.setAttribute('valid', '');
            element.removeAttribute('invalid');
            [...inputElements].forEach(input => input && input.removeAttribute('invalid'));
            if (!hideErrors) errorContainer.textContent = '';
        }
    }

    /**
     * Get input errors based upon validation types.
     * @param {*} value
     */
    function getErrors(value: any) {
        const hasErrorValidation = required;

        let errors: string[] = [];

        if (!hasErrorValidation) return errors;

        if (required) {
            errors = validateCustomRegex({
                value,
                required,
                requiredErrorMessage,
                validationErrorMessage: null,
                validationRegex: null,
            });
        }

        return errors;
    }
};

CheckboxListElement.props = {
    valid: {
        type: Boolean,
        reflectToAttr: true,
    },
    required: {
        type: Boolean,
    },
    requiredErrorMessage: {
        type: String,
    },
    hideErrors: {
        type: Boolean,
        reflectToAttr: true,
    },
};

defineElement('bpd-checkbox-list', CheckboxListElement);
