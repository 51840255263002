import { defineElement, FC, onDidLoad, useListen, useProp, useRef } from '@atomify/hooks';

const HEADER_SHADOW_CLASS = 'header--has-shadow';
const PAGE_HAS_SCROLLED = 'header--has-scrolled';
const HEADER_POP_OUT = 'header--pop-out';

const BPDHeader: FC = ({ element }) => {
    const height = useRef(0);
    const scrollValue = useRef(0);
    const prevScrollValue = useRef(0);
    const translateValue = useRef(0);
    const scrollingDistanceHeight = useRef(0);
    const stickyElementTop = useRef(0);
    const isScrollingDown = useRef(false);
    const stickyElement = useRef<ChildNode | undefined>();

    const [fixed] = useProp('fixed', false);
    const [condenses] = useProp('condenses', false);
    const [reveals] = useProp('reveals', false);
    const [shadow] = useProp('shadow', false);

    useListen(window, 'scroll', scrollHandler);

    onDidLoad(() => {
        stickyElement.current = getStickyElement();
        scrollHandler();
    });

    // Get header height
    function getMaxHeaderHeight() {
        return fixed ? scrollingDistanceHeight.current : height.current + 5;
    }

    /**
     * Gets element with [sticky] attribute
     * Takes the first direct child when [sticky] attribute is not set
     */
    function getStickyElement() {
        const elements = element.childNodes;

        let stickyElement;

        for (let i = 0, len = elements.length; i < len; i++) {
            if (elements[i].nodeType === Node.ELEMENT_NODE) {
                const element = elements[i];

                if ((element as any).hasAttribute('sticky')) {
                    stickyElement = element;
                }

                if (!stickyElement) {
                    stickyElement = element;
                }
            }
        }

        return stickyElement;
    }

    function isOnScreen() {
        return height.current !== 0 && translateValue.current < height.current;
    }

    function scrollHandler() {
        height.current = element.offsetHeight;
        scrollingDistanceHeight.current = stickyElement.current
            ? height.current - (stickyElement.current as HTMLDivElement).offsetHeight
            : 0;
        stickyElementTop.current = stickyElement.current
            ? (stickyElement.current as HTMLDivElement).offsetTop
            : 0;

        scrollValue.current = window.pageYOffset;

        setHeaderScrollClass();
        calculateHeaderTranslateValue();
    }

    function setHeaderScrollClass() {
        let action: 'add' | 'remove' = window.scrollY > 100 ? 'add' : 'remove';
        element.classList[action](PAGE_HAS_SCROLLED);

        action = window.scrollY < prevScrollValue.current ? 'add' : 'remove';
        element.classList[action](HEADER_POP_OUT);
    }

    /**
     * Calculates the header translate value based on the options that are set
     */
    function calculateHeaderTranslateValue() {
        const scrollDiff = scrollValue.current - prevScrollValue.current;

        if (condenses || !fixed) {
            if (reveals) {
                translateValue.current = Math.min(
                    getMaxHeaderHeight(),
                    Math.max(0, translateValue.current + scrollDiff),
                );
            } else {
                translateValue.current = Math.min(
                    getMaxHeaderHeight(),
                    Math.max(0, scrollValue.current),
                );
            }
        }

        if (scrollValue.current >= scrollingDistanceHeight.current) {
            translateValue.current =
                condenses && !fixed
                    ? Math.max(scrollingDistanceHeight.current, translateValue.current)
                    : translateValue.current;
        }

        isScrollingDown.current = scrollValue.current > prevScrollValue.current;
        prevScrollValue.current = scrollValue.current;

        if (scrollValue.current <= 0) {
            transformHeader(0);
        } else {
            transformHeader(translateValue.current);
        }

        if (shadow) {
            applyShadow();
        }
    }

    /**
     * Applies the calculated value from _calculateHeaderTranslateValue to the header
     * and applies it to the sticky element when a sticky element is available
     */

    function transformHeader(value: number) {
        element.style.transform = `translate3d(0, -${value}px, 0)`;

        if (stickyElement.current) {
            (stickyElement.current as HTMLDivElement).style.transform = `translate3d(0, ${Math.min(
                value,
                scrollingDistanceHeight.current,
            ) - stickyElementTop.current}px, 0)`;

            if (condenses && value >= stickyElementTop.current) {
                (stickyElement.current as HTMLDivElement).style.transform = `translate3d(0 ,${Math.min(
                    value,
                    scrollingDistanceHeight.current,
                ) - stickyElementTop.current}px, 0)`;
            } else {
                (stickyElement.current as HTMLDivElement).style.transform = `translate3d(0, 0, 0)`;
            }
        }
    }

    function applyShadow() {
        if (fixed) {
            element.classList.add(HEADER_SHADOW_CLASS);
        }

        if (scrollValue.current <= 0 || !this.isOnScreen) {
            element.classList.remove(HEADER_SHADOW_CLASS);
        }

        if (isOnScreen() && scrollValue.current > 0 && !isScrollingDown.current) {
            element.classList.add(HEADER_SHADOW_CLASS);
        }
    }
};

BPDHeader.props = {
    fixed: {
        type: Boolean,
    },
    condenses: {
        type: Boolean,
    },
    reveals: {
        type: Boolean,
    },
    shadow: {
        type: Boolean,
    },
};

defineElement('bpd-header', BPDHeader);
