import {
    Component,
    css,
    defineElement,
    FC,
    useBindMethod,
    useElement,
    useEvent,
    useRef,
    useStyles,
} from '@atomify/hooks';
import { useHistory, useScrollTo } from '@source/utilities/hooks';

import { appendChildren, generateUrl, htmlToElement, LoadPageOptions, once } from './utilities';

export interface AjaxContainer extends Component {
    loadPage: (data: LoadPageOptions, overwriteConfig?: AjaxContainerOptions) => void;
}

interface AjaxContainerOptions {
    onLoadingClass?: string;
    eventAfterLoad?: string;
    targetContainer?: string;
    itemsOnAppearance?: string;
    append?: boolean;
    loadAfterTransitionEnd?: string | boolean;
    scrollToAlignment?: string;
}

export const AjaxContainer: FC<AjaxContainer> = ({ element }) => {
    const options = useRef(getAjaxContainerOptions(element));
    const renderContainer = useElement<HTMLElement>(options.current.targetContainer);
    const event = useEvent<{ html: DocumentFragment }>({
        eventName: options.current.eventAfterLoad,
    });
    const to = useScrollTo({ duration: 300, offset: 100 });

    const { push } = useHistory();

    useStyles(
        () =>
            css`
                ajax-container {
                    display: block;
                }
            `,
    );

    useBindMethod('loadPage', (data: LoadPageOptions, overwriteConfig: AjaxContainerOptions) => {
        const url = generateUrl(data);

        if (renderContainer.current) {
            push(url);
            prepareContentLoading(url, overwriteConfig);
        }
    });

    /**
     * Scrolls to the element when the transition of the loading state ended.
     * @param {string} url
     */
    function prepareContentLoading(url: string, overwriteConfig: AjaxContainerOptions) {
        const { loadAfterTransitionEnd } = Object.assign({}, options.current, overwriteConfig);

        if (loadAfterTransitionEnd) {
            to(renderContainer.current!).then(() => {
                toggleLoading(true);
                once(renderContainer.current!, 'transitionend', () => {
                    fetchContent(url, overwriteConfig);
                });
            });
        } else {
            toggleLoading(true);
            fetchContent(url, overwriteConfig);
        }
    }

    /**
     * Fetches content based upon the generated url.
     * @param {string} url
     */
    async function fetchContent(url: string, overwriteConfig: AjaxContainerOptions) {
        const res = await fetch(url);
        const page = await res.text();

        appendContent(page, overwriteConfig);
    }

    /**
     * Appends content based upon the queried children of the target container
     * @param {string} page
     */
    function appendContent(page: string, overwriteConfig: AjaxContainerOptions) {
        const { targetContainer, append, itemsOnAppearance } = Object.assign(
            {},
            options.current,
            overwriteConfig,
        );
        const html = htmlToElement(page);
        const items = Array.from(html.querySelector(targetContainer)!.children) as HTMLElement[];
        const container = renderContainer.current!;

        if (!append) container.innerHTML = '';

        appendChildren(container, items, itemsOnAppearance);

        toggleLoading(false);

        if (append && items.length > 0) {
            to(items[0] as HTMLElement);
        }

        event.emit({ html });
    }

    /**
     * Adds a loading class to the component.
     * @param {boolean} state
     */
    function toggleLoading(state: boolean) {
        const action = state ? 'add' : 'remove';
        renderContainer.current!.classList[action](options.current.onLoadingClass);
    }

    /**
     * Generates the options for the ajax container based upon data attributes.
     * @param {AjaxContainer} element
     * @returns {}
     */
    function getAjaxContainerOptions(element: AjaxContainer) {
        const {
            onLoadingClass = 'is--loading',
            eventAfterLoad = 'updated',
            targetContainer = '',
            itemsOnAppearance = 'is--added',
            scrollToAlignment = 'start',
        } = element.dataset;

        return {
            onLoadingClass,
            eventAfterLoad,
            targetContainer,
            itemsOnAppearance,
            append: element.dataset.append || false,
            loadAfterTransitionEnd: element.dataset.loadAfterTransitionEnd || false,
            scrollToAlignment,
        };
    }
};

defineElement('ajax-container', AjaxContainer);
