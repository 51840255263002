export const useHistory = () => {
    function pushHistory(url: string) {
        window.history.pushState({}, document.title, url);
    }

    function replaceHistory(url: string) {
        window.history.replaceState({}, document.title, url);
    }

    return {
        push: pushHistory,
        replace: replaceHistory,
    };
};
