import { defineElement, FC, onDidLoad, useBindMethod } from '@atomify/hooks';
import { h } from '@atomify/jsx';
import { useStylesheet } from '@source/utilities/hooks';
import cookies from 'js-cookie';

import style from './cookies.scss';
import Cookiebar from './utilities/cookiebar';
import { CookieName, CookiesInterface } from './utilities/cookies-types';

const COOKIE_DEFAULT_VALUE = '1';
const COOKIE_DECLINED_VALUE = '0';

const COOKIES_ACCEPTED = 'accepted';
const COOKIES_VERSION = 'version';

export const Cookies: FC<CookiesInterface> = ({ element }) => {
    const cookiePrefix = 'BPD';

    const {
        cookiebarTitle = 'Wij gebruiken cookies',
        policyVersion = '1',
        cookiebarText = 'Cookie settings',
        settingsLabel = 'Instellingen wijzigen',
        settingsUrl = '',
        acceptLabel = 'Accepteren',
    } = element.dataset;

    const cookieName = {
        functional: 'functional',
        analytics: 'analytics',
        advertising: 'advertising',
    };

    const cookieBarVersion = policyVersion;

    const config = {
        cookiePrefix: cookiePrefix,
        version: cookieBarVersion,
        cookies: [
            {
                name: cookieName.functional,
                default: COOKIE_DEFAULT_VALUE,
            },
            {
                name: cookieName.analytics,
                default: COOKIE_DEFAULT_VALUE,
            },
            {
                name: cookieName.advertising,
                default: COOKIE_DECLINED_VALUE,
            },
        ],
    };

    useStylesheet(style);

    useBindMethod('setCookie', setCookie);
    useBindMethod('removeCookie', removeCookie);
    useBindMethod('removeCookiebar', removeCookiebar);
    useBindMethod('getCookie', getCookie);
    useBindMethod('cookieIsValid', cookieIsValid);
    useBindMethod('addGlobalCookies', addGlobalCookies);

    onDidLoad(() => {
        if (getCookie(COOKIES_VERSION) !== config.version) {
            removeInvalidatedCookies();
        }

        setDefaultCookies();

        if (!getCookie(COOKIES_ACCEPTED)) {
            setCookie(COOKIES_ACCEPTED, COOKIE_DECLINED_VALUE);
        }

        if (
            getCookie(COOKIES_VERSION) !== config.version ||
            getCookie(COOKIES_ACCEPTED) === COOKIE_DECLINED_VALUE
        ) {
            renderCookiebar();
        }
    });

    function acceptAllCookies() {
        const acceptedCookies: any = {};

        config.cookies.forEach(cookie => {
            setCookie(cookie.name, COOKIE_DEFAULT_VALUE);
            acceptedCookies[cookie.name] = COOKIE_DEFAULT_VALUE;
        });

        addGlobalCookies(acceptedCookies);

        window.location.reload();
    }

    function renderCookiebar() {
        element.append(
            <Cookiebar
                title={cookiebarTitle}
                text={cookiebarText}
                settingsLabel={settingsLabel}
                settingsUrl={settingsUrl}
                acceptLabel={acceptLabel}
                onAccept={() => acceptAllCookies()}
            />,
        );
    }

    /**
     * Sets the global cookies
     *
     * @param cookies object
     */
    function addGlobalCookies(cookies: { [cookieName: string]: string }) {
        cookies.date = (new Date(Date.now()) as any) + '';
        cookies.version = config.version;

        Object.keys(cookies).forEach(cookieName => {
            cookies[prefixCookieName(cookieName)] = cookies[cookieName];
            delete cookies[cookieName];
        });

        setCookie(COOKIES_ACCEPTED, cookies);
        setCookie(COOKIES_VERSION, config.version);
    }

    /**
     * Removes cookiebar
     */
    function removeCookiebar() {
        element.innerHTML = '';
    }

    /**
     * Checks if cookie is valid and version is correct
     * @param {String} name
     * @returns {Boolean}
     */
    function cookieIsValid(name: CookieName) {
        return (
            getCookie(COOKIES_VERSION) === config.version &&
            cookies.get(prefixCookieName(name)) === config.version
        );
    }

    /**
     * Private function
     */
    function removeInvalidatedCookies() {
        config.cookies.forEach(cookie => {
            removeCookie(cookie.name);
        });

        removeCookie(COOKIES_ACCEPTED);
        removeCookie(COOKIES_VERSION);
    }

    function setDefaultCookies() {
        config.cookies.forEach(cookie => {
            if (!getCookie(cookie.name) && cookie.default === COOKIE_DEFAULT_VALUE) {
                setCookie(cookie.name, cookie.default);
            }
        });
    }

    /**
     * Sets cookie with given value
     * @param {String} name
     * @param {any} value
     */
    function setCookie(name: CookieName, value: string | object) {
        cookies.set(prefixCookieName(name), value, { expires: 365 });
    }

    /**
     * Remove cookie with given value
     * @param {String} name
     */
    function removeCookie(name: CookieName) {
        cookies.remove(prefixCookieName(name));
    }

    /**
     * Prefixes the cookie name
     * @param {String} name
     * @returns {String} name
     */
    function prefixCookieName(name: CookieName) {
        return `${config.cookiePrefix}-cookie-${name}`;
    }

    /**
     * Gets cookie with given value
     * @param {String} name
     * @returns {Any} value
     */
    function getCookie(name: CookieName) {
        return cookies.get(prefixCookieName(name));
    }
};

defineElement('bpd-cookies', Cookies);
