export interface ValidationType {
    [key: string]: (value: any, element?: HTMLLabelElement) => undefined | string;
}

export interface CustomValidationRegex {
    required: boolean;
    validationRegex: string | null;
    validationErrorMessage: string | null;
    requiredErrorMessage: string | null;
    value: any;
    maxLength?: number | null;
    maxLengthErrorMessage?: string | null;
}

let validationTypes: ValidationType = {
    required: (value, element) =>
        value ? undefined : `Vul uw ${transformLabel(element!.textContent)} in.`,
    complaintRequired: value => (value ? undefined : `“Laat ons weten wat uw klacht is”.`),
    contactUsRequired: value =>
        value ? undefined : `“Laat ons weten waarover u met ons in contact wilt komen.”`,
    max250Characters: value =>
        value && value.length <= 250
            ? undefined
            : `Wilt u de klacht iets korter beschrijven? Dat kan in maximaal 250 woorden.`,
    email: value =>
        value && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value)
            ? 'Het lijkt erop dat het e-mailadres niet klopt. Controleer of het e-mailadres een @ en een punt bevat. En of er geen spaties in staan.'
            : undefined,
    phone: value =>
        value &&
        !/(^(06[1-9][0-9]{7}|06[1-9][0-9]{7}|0[1-9][0-9][1-9][0-9]{6}|0[1-9][0-9][1-9][0-9]{6}|0[1-9][0-9]{2}[1-9][0-9]{5}|0[1-9][0-9]{2}[1-9][0-9]{5})$)/.test(
            value,
        )
            ? 'Vul je telefoonnummer in'
            : undefined,
    number: value =>
        isNaN(Number(value)) ? 'Vul hier de cijfers van je huisnummer in.' : undefined,
    text: value =>
        value && !/^[a-zA-Z\s]*$/.test(value) ? 'U kunt hier alleen tekst invullen' : undefined,
    fullDate: value =>
        value && !/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(value)
            ? 'U heeft geen geldige datum ingevuld (bijvoorbeeld 01-01-2000)'
            : undefined,
    zipcode: value =>
        value && !/^[1-9][0-9]{3}\s*[a-z]{2}$/i.test(value)
            ? 'We hebben de postcode als volgt nodig: 1234 AB. Zet een spatie tussen de cijfers en letters.'
            : undefined,
    checkboxRadioRequired: (value, element) =>
        value ? undefined : `Selecteer ${transformLabel(element!.textContent)}`,
    textareaRequired: (value, element) =>
        value ? undefined : `${transformLabel(element!.textContent)} is een verplicht veld.`,
};

const transformLabel = (text: string | null) => (!text ? '' : text.toLowerCase());

const validateField = (value: any, validateFs: string, inputElement: HTMLLabelElement) => {
    const errors: string[] = [];
    const types = validateFs.indexOf(',') > -1 ? validateFs.split(',') : [validateFs];

    types.forEach(type => {
        const error = validationTypes[type as keyof typeof validationTypes](value, inputElement);
        if (error) errors.push(error);
    });

    return errors;
};

const validateCustomRegex = ({
    value,
    requiredErrorMessage,
    validationErrorMessage,
    required,
    validationRegex,
    maxLength,
    maxLengthErrorMessage,
}: CustomValidationRegex) => {
    const errors: string[] = [];

    if (required && !value) {
        errors.push(requiredErrorMessage || '');
    }

    if (maxLength && value.length > maxLength) {
        errors.push(maxLengthErrorMessage || '');
    }

    if (validationRegex) {
        const regex = new RegExp(validationRegex);
        if (!regex.test(value)) errors.push(validationErrorMessage || '');
    }

    return errors;
};

const extendValidations = (validations: ValidationType) => {
    validationTypes = Object.assign({}, validationTypes, validations);
};

export { validationTypes, validateField, extendValidations, validateCustomRegex };
