import { defineElement, FC, useElement, useElements, useListen, useRef } from '@atomify/hooks';
import { AjaxContainer } from '@atoms/ajax-container';
import { useLoadMore } from '@atoms/load-more';
import { BPDInput } from '@source/components/atoms/form-elements';
import { BPDFilterDropdown } from '@source/components/molecules/filter-dropdown';
import DataLayerInterface from '@source/utilities//gtm.types';
import { GTMPush } from '@source/utilities/gtm';
import { useDelegateListen } from '@source/utilities/hooks/use-delegate-listen';
import { FormChangeCallback, useFormChanges } from '@source/utilities/hooks/use-form-changes';

const GTM_EVENT_DEFAULT_DATA = {
    event: 'filters',
    eventCategory: 'ActueelFilters',
};

export const ContentOverview: FC = () => {
    const [setLoading, onUpdateSearchReadmore, loadmoreElement] = useLoadMore('content-overview');
    const ajaxContainer = useElement<AjaxContainer>('ajax-container');
    const showLoadMore = useRef<boolean>(true);
    const filters = useElements<HTMLElement[]>('bpd-filter-dropdown');
    const inputs = useElements<BPDInput[]>('bpd-input');
    const formDesktop = useElement<HTMLFormElement>('[js-hook-content-overview-filters-desktop]');
    const formMobile = useElement<HTMLFormElement>('[js-hook-content-overview-filters-mobile]');

    useListen(filters, 'filter-changed', (e: CustomEvent) => {
        const data = e.detail;

        ajaxContainer.current!.loadPage(
            {
                url: window.location.href,
                params: {
                    ...data,
                    p: 1,
                },
            },
            {
                append: false,
                loadAfterTransitionEnd: true,
            },
        );
    });

    useDelegateListen('[js-hook-no-result-trigger]', 'click', (e: MouseEvent) => {
        e.preventDefault();

        GTMPush({
            event: 'Map Interaction',
            eventCategory: 'ActueelFilters',
            eventAction: 'reset filters',
        });

        Array.from(inputs.current).forEach(input => {
            if (input!.resetInput) input!.resetInput();
        });
    });

    useListen(ajaxContainer, 'updated', () => {
        setLoading(false);

        if (loadmoreElement.current && loadmoreElement.current.parentNode) {
            loadmoreElement.current.parentNode.remove();
        }
    });

    onUpdateSearchReadmore(({ page, hasLoadMore }) => {
        ajaxContainer.current!.loadPage({ params: { p: page } });
        showLoadMore.current = hasLoadMore;
    });

    const getFilterName = (element: Element | null) =>
        element?.closest<BPDFilterDropdown>('bpd-filter-dropdown')?.title || '';

    const getCheckboxLabelText = (element: Element | null) =>
        element?.closest<BPDInput>('bpd-input')?.innerText ?? '';

    const handleGTMEvent = (result: FormChangeCallback) => {
        const { additions, deletions } = result;
        if (!additions.length && !deletions.length) return;

        const checkboxElement = additions.length ? additions[0].element : deletions[0].element;

        const eventAction = getFilterName(checkboxElement);
        const eventLabel = `${additions.length ? 'Add' : 'Remove'} ${getCheckboxLabelText(
            checkboxElement,
        )}`;

        GTMPush<DataLayerInterface>({ ...GTM_EVENT_DEFAULT_DATA, eventAction, eventLabel });
    };

    useFormChanges(formDesktop, handleGTMEvent);
    useFormChanges(formMobile, handleGTMEvent);
};

defineElement('bpd-content-overview', ContentOverview);
