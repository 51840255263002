import { Component, Listen, Prop, Query, Watch } from '@atomify/core';

import VimeoVideo from './platform/vimeo';
import YoutubeVideo from './platform/youtube';

export interface VideoOptions {
    element: BPDVideo;
    player: HTMLDivElement;
    videoPlatform: string | null;
    videoId: string;
    videoTime: number;
    videoControls: number;
    videoMuted: number;
    videoAutoplay: number;
    videoPlaysinline: number;
    videoLoop: number;
}

const PLAYER_HOOK = '[js-hook-video-player]';

const VIDEO_PLAY_HOOK = '[js-hook-video-play]';

const VIDEO_READY_CLASS = 'video--is-initialised';
const VIDEO_PLAYING_CLASS = 'video--is-playing';
const VIDEO_PAUSED_CLASS = 'video--is-paused';
const VIDEO_REPLAY_CLASS = 'video--is-ended';
const VIDEO_HAS_PLAYED_CLASS = 'video--has-played';

@Component({
    tag: 'bpd-video',
})
export class BPDVideo extends HTMLElement {
    @Query(PLAYER_HOOK) playerContainer: HTMLDivElement;
    @Query(VIDEO_PLAY_HOOK) playButton: HTMLButtonElement;

    @Prop() inViewPort: boolean = false;
    @Prop() player: YoutubeVideo | VimeoVideo;

    @Watch('inViewPort')
    inViewPortChanged(newValue: boolean) {
        if (newValue) {
            this.initVideo();
        }
    }

    @Listen('click', { target: 'playButton' })
    playVideo() {
        this.player.play();
        this.playing();
    }

    /**
     * Called by the video loader when the video gets in view.
     * @memberof BPDVideo
     */
    public async initVideo() {
        const options = constructVideoOptions(this, this.playerContainer);
        const Player = await this.getPlayer(options.videoPlatform);

        this.player = new Player(options);
    }

    /**
     * Called when the video platform is finished loading.
     * @memberof BPDVideo
     */
    public ready() {
        this.classList.add(VIDEO_READY_CLASS);
        this.classList.add(VIDEO_PAUSED_CLASS);
    }

    /**
     * Called when the video is starting to play
     * @memberof BPDVideo
     */
    public playing() {
        this.classList.remove(VIDEO_REPLAY_CLASS);
        this.classList.remove(VIDEO_PAUSED_CLASS);
        this.classList.add(VIDEO_PLAYING_CLASS);
        this.classList.add(VIDEO_HAS_PLAYED_CLASS);
    }

    /**
     * Called when the video is being paused
     * @memberof BPDVideo
     */
    public paused() {
        this.classList.remove(VIDEO_PLAYING_CLASS);
        this.classList.add(VIDEO_PAUSED_CLASS);
    }

    /**
     * Called when the video is ended.
     * @memberof BPDVideo
     */
    public ended() {
        this.classList.remove(VIDEO_PLAYING_CLASS);
        this.classList.add(VIDEO_REPLAY_CLASS);
    }

    private async getPlayer(
        platform: string | null,
    ): Promise<typeof YoutubeVideo | typeof VimeoVideo> {
        return new Promise(async resolve => {
            switch (platform) {
                case 'youtube':
                    const youtubePlayer = await import(
                        /* webpackChunkName: "Youtube-Player" */ './platform/youtube'
                    );
                    resolve(youtubePlayer.default);
                    return;
                case 'vimeo':
                    const vimeoPlayer = await import(
                        /* webpackChunkName: "Vimeo-Player" */ './platform/vimeo'
                    );
                    resolve(vimeoPlayer.default);
                    return;
            }
        });
    }
}

/**
 * Construct the video options object
 * @param {NodeList} element
 * @returns {Object}
 */
function constructVideoOptions(element: BPDVideo, player: HTMLDivElement): VideoOptions {
    const {
        videoPlatform = null,
        videoId = '',
        videoTime = '0',
        videoControls = '1',
        videoMuted = '0',
        videoAutoplay = '0',
        videoLoop = '0',
        videoPlaysinline = '0',
    } = element.dataset;

    return {
        element,
        player,
        videoPlatform,
        videoId,
        // Boolean options:
        videoTime: parseInt(videoTime, 10),
        videoControls: parseInt(videoControls, 10),
        videoMuted: parseInt(videoMuted, 10),
        videoAutoplay: parseInt(videoAutoplay, 10),
        videoPlaysinline: parseInt(videoPlaysinline, 10),
        videoLoop: parseInt(videoLoop, 10),
    };
}
