import { Component, Listen, QueryAll } from '@atomify/core';

const VISIBLE_CLASS = 'section-tabbed-quote__panel--visible';

@Component({
    tag: 'bpd-section-tabbed-quote',
})
export class SectionTabbedQuote extends HTMLElement {
    @QueryAll('[js-hook-tabbed-radio-button]') tabbedRadioButtons: HTMLElement[];
    @QueryAll('[js-hook-tabbed-panel]') tabbedPanels: HTMLElement[];
    targetRadioButton: HTMLElement | null;
    targetTab: HTMLElement | null;

    @Listen('click', { target: 'tabbedRadioButtons' })
    handleClick(e: MouseEvent) {
        this.targetRadioButton?.setAttribute('checked', 'false');
        this.targetTab?.classList.remove(VISIBLE_CLASS);

        const target = e.target as HTMLElement;
        const index = Number(target.getAttribute('js-hook-tabbed-radio-button'));
        this.tabbedRadioButtons[index].setAttribute('checked', 'true');
        this.tabbedPanels[index].classList.add(VISIBLE_CLASS);

        this.targetRadioButton = this.tabbedRadioButtons[index];
        this.targetTab = this.tabbedPanels[index];
    }

    componentDidLoad() {
        this.targetRadioButton = this.tabbedRadioButtons[0];
        this.targetTab = this.tabbedPanels[0];
    }
}
