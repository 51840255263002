import { h } from '@atomify/jsx';

interface ContainerInterface {
    extraClass?: string;
    children?: NodeList;
}

export const Container = ({ extraClass = '', children }: ContainerInterface) => (
    <div class={`o-container ${extraClass}`}>{children}</div>
);
