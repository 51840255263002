import { Component, defineElement, FC, onDidLoad, useEvent, useProp, useRef } from '@atomify/hooks';
import { useInput, UseInputElement } from '@source/utilities/hooks';

// Local imports
import { validateCustomRegex, validateField } from './validation';

const FLOATING_LABEL = 'floating-label-active';

export interface BPDInput extends Component, UseInputElement {
    bindValue: string;
    valid: boolean;
    displayName: string;
}

export const Input: FC<BPDInput> = ({ element }) => {
    const [, setBindValue] = useProp<string>('bindValue', '');
    const [, setIsValid] = useProp<boolean>('valid', false);
    const [,] = useProp<string | null>('displayName', null);
    const [validationTypes] = useProp<string>('validationTypes', '');
    const [validationRegex] = useProp<string | null>('validationRegex', null);
    const [validationErrorMessage] = useProp<string | null>('validationErrorMessage', null);
    const [requiredErrorMessage] = useProp<string | null>('requiredErrorMessage', null);
    const [maxLength] = useProp<number | null>('maxLength', null);
    const [maxLengthErrorMessage] = useProp<string | null>('maxLengthErrorMessage', null);

    const [hideErrors] = useProp<boolean>('hideErrors', false);
    const [, setIsTouched] = useProp<boolean>('isTouched', false);
    const [, setIsFocussed] = useProp<boolean>('isFocussed', false);

    const inputElement = useRef<HTMLInputElement | null>(null);
    const errors = useRef<string[]>([]);
    const errorMessage = useRef<string>('');

    const focusEvent = useEvent({ eventName: 'focus' });
    const focusOutEvent = useEvent({ eventName: 'focusout' });

    const { inputs, errorHolder, inputLabel, bindValueChanged, inputValidate } = useInput({
        inputElements: 'input, select, textarea',
        errorTarget: '[role="alert"]',
        inputLabelTarget: 'label',
        onInputChange,
        onInputFocus,
        onInputBlur,
        onInputInput,
        onInputFocusout,
        onRebindValue,
        onValidate,
        resetInput,
    });

    onDidLoad(() => {
        setInput();

        if (inputElement.current) {
            const input = inputElement.current;

            setBindValue(input.value);
            setIsValid(input.checkValidity());
            toggleLabelClass();
        }
    });

    function resetInput() {
        const input = inputElement.current;
        if (!input) return;

        if (input.type === 'checkbox' || input.type === 'radio') {
            input.checked = false;
            element.validate();
        } else {
            onRebindValue('');
        }
    }

    function onInputChange() {
        const input = inputElement.current!;
        setBindValue(input.value);
        element.validate();
    }

    function onInputFocus() {
        setIsFocussed(true);
        updateErrorValidState();
        toggleLabelClass(true);
        focusEvent.emit(element);
    }

    function onInputBlur() {
        setIsTouched(true);
        setIsFocussed(false);
        updateErrorValidState();
    }

    function onInputInput() {
        toggleLabelClass(true);
    }

    function onInputFocusout() {
        toggleLabelClass();
        focusOutEvent.emit(element);
    }

    /**
     * Adds a rebindValue function to the input component
     */
    function onRebindValue(value: any) {
        const input = inputElement.current;

        if (input) {
            setBindValue(value);
            input.value = value;

            element.validate();
            bindValueChanged.emit(value);
            toggleLabelClass();
        }
    }

    /**
     * Adds a validate function to the input component
     * It validates the component based upon validation types and the value.
     */
    function onValidate() {
        const input = inputElement.current;
        if (input?.hasAttribute('disabled-required')) return true;

        if (!input || input.disabled) return true;

        const value = getValue(input.value);

        // Set errors
        errors.current = getErrors(value);

        const hasErrors = errors.current.length === 0;

        // Set is valid property
        setIsValid(hasErrors);

        // Set error message
        errorMessage.current = element.valid ? '' : errors.current[0];

        // Emit input validate event.
        inputValidate.emit({
            errorMessage: errorMessage.current,
            value,
            isValid: element.valid,
            input: inputElement.current!,
        });

        updateErrorValidState();

        return element.valid;
    }

    /**
     * Sets and removes errors from the error holder based upon the active error message
     */
    function updateErrorValidState() {
        const input = inputElement.current;
        if (input?.hasAttribute('disabled-required')) return;
        const errorContainer = errorHolder.current;

        if (!input || !errorContainer) return;

        if (errors.current.length > 0) {
            element.setAttribute('invalid', '');
            input.setAttribute('invalid', '');
            element.removeAttribute('valid');

            if (!hideErrors) errorContainer.textContent = errorMessage.current;
        }

        if (element.valid) {
            element.setAttribute('valid', '');
            element.removeAttribute('invalid');
            input.removeAttribute('invalid');
            if (!hideErrors) errorContainer.textContent = '';
        }
    }

    /**
     * Get input errors based upon validation types.
     * @param {*} value
     */
    function getErrors(value: any) {
        const hasErrorValidation =
            maxLength ||
            validationTypes ||
            validationTypes.length > 0 ||
            validationRegex ||
            inputElement.current!.required;
        let errors: string[] = [];

        if (!hasErrorValidation) return errors;

        if (validationRegex || inputElement.current!.required || maxLength) {
            errors = validateCustomRegex({
                value,
                required: inputElement.current!.required,
                requiredErrorMessage,
                validationRegex,
                validationErrorMessage,
                maxLength,
                maxLengthErrorMessage,
            });
        }

        if (validationTypes || validationTypes.length > 0) {
            errors = validateField(value, validationTypes, inputLabel.current!);
        }

        return errors;
    }

    /**
     * Get input value based upon type
     * @param {*} val
     * @returns
     */
    function getValue(val: any) {
        const input = inputElement.current!;
        let value;

        if (input.type === 'checkbox') {
            value = input.checked ? true : false;
        } else {
            value = val || '';
        }

        return value;
    }

    /**
     * Toggle the label as floating label
     * @param {boolean} [forceAnimateLabel=false]
     */
    function toggleLabelClass(forceAnimateLabel: boolean = false) {
        const action = forceAnimateLabel || inputElement.current!.value ? 'add' : 'remove';
        element.classList[action](FLOATING_LABEL);
    }

    /**
     * Checks if there is an input and sets the current input
     */
    function setInput() {
        const input = inputs.current;

        if (input.length === 0) throw new Error(`There is no select, input or textarea found.`);

        inputElement.current = input[0];
    }
};

Input.props = {
    bindValue: {
        type: String,
    },
    valid: {
        type: Boolean,
        reflectToAttr: true,
    },
    validationTypes: {
        type: String,
    },
    hideErrors: {
        type: Boolean,
        reflectToAttr: true,
    },
    isTouched: {
        type: Boolean,
    },
    isFocussed: {
        type: Boolean,
    },
    validationRegex: {
        type: String,
    },
    validationErrorMessage: {
        type: String,
    },
    requiredErrorMessage: {
        type: String,
    },
    displayName: {
        type: String,
    },
    maxLength: {
        type: Number,
    },
    maxLengthErrorMessage: {
        type: String,
    },
};

defineElement('bpd-input', Input);
