const html = document.documentElement;
const body = document.body;

interface Elements {
    body: HTMLElement;
    html: HTMLElement;
}

export const elements: Elements = {
    html,
    body,
};
