import { Component, Query, QueryAll } from '@atomify/core';

const STEP_WIDTH = 0.5;

@Component({
    tag: 'bpd-projects-animation',
})
export class ProjectAnimation extends HTMLElement {
    containerPosition: number = 0;
    slides: any;
    containerHeight: number;
    timeout: number | 0;

    @Query('[js-hook-projects-animation]') projectsAnimation: any;
    @Query('[js-hook-projects-animation-container]') projectAnimationContainer: HTMLElement;
    @QueryAll('[js-hook-projects-animation-image]') projectsAnimationSlides: HTMLElement[];

    outsideViewport(element: any) {
        if (
            element.slide.getBoundingClientRect().top + element.slideHeight <=
            0 - document.documentElement.scrollTop
        ) {
            return true;
        } else {
            return false;
        }
    }

    animateNow() {
        this.containerPosition = this.containerPosition + STEP_WIDTH;
        this.projectAnimationContainer.style.transform = `translateY(-${this.containerPosition}px)`;

        this.slides.forEach((element: any) => {
            if (this.outsideViewport(element)) {
                element.shiftTimes += 1;
                element.slide.style.transform = `translateY(${element.containerHeight *
                    element.shiftTimes}px)`;
            }
        });

        this.timeout = window.requestAnimationFrame(() => this.animateNow());
    }

    init() {
        this.projectAnimationContainer.addEventListener('mouseenter', () => {
            this.pause();
        });

        this.projectAnimationContainer.addEventListener('mouseleave', () => {
            this.play();
        });

        return new Promise(resolve => {
            this.containerHeight = this.projectAnimationContainer.getBoundingClientRect().height;

            this.slides = Array.from(this.projectsAnimationSlides).map(slide => {
                return {
                    slide,
                    slideHeight: slide.getBoundingClientRect().height,
                    containerHeight: slide.parentElement?.scrollHeight || 0,
                    shiftTimes: 0,
                };
            });

            resolve();
        });
    }

    pause() {
        if (this.timeout) {
            console.log('removing animation frame');
        }
        window.cancelAnimationFrame(this.timeout);
    }

    play() {
        this.animateNow();
    }

    async componentDidLoad() {
        await this.init();
        this.animateNow();
    }
}
