export const rafThrottle = (callback: (...args: any[]) => void) => {
    let requestId: null | number = null;

    let lastArgs: any = null;

    const wrapperFunction = (...args: any[]) => {
        // Always capture the latest value
        lastArgs = args;

        // There is already a frame queued
        if (requestId) {
            return;
        }

        // Schedule a new frame
        requestId = requestAnimationFrame(() => {
            requestId = null;
            callback(...lastArgs);
        });
    };

    wrapperFunction.cancel = () => {
        if (requestId !== null) {
            cancelAnimationFrame(requestId);
            requestId = null;
        }
    };

    return wrapperFunction;
};
