import './header';

import { Component, Listen, Query } from '@atomify/core';
import { once } from '@source/components/atoms/ajax-container/utilities';
@Component({
    tag: 'bpd-navigation',
})
export class BPDHeader extends HTMLElement {
    isOpenClass: string = 'navigation-secondary--is-open';
    isOpenClassMobile: string = 'navigation-mobile--is-open';
    searchIsOpenClass: string = 'search-modal--is-open';
    hasScrolledClass: string = 'header--has-scrolled';

    @Query('[js-hook-navigation-secondary]') menuSecondary: HTMLElement;
    @Query('[js-hook-navigation-secondary-background]') menuSecondaryBackground: HTMLElement;
    @Query('[js-hook-menu-toggle]') menuToggle: HTMLElement;
    @Query('[js-hook-navigation-secondary-close-button]') closeButton: HTMLElement;
    @Query('[js-hook-search-modal-background]') searchModalBackground: HTMLDivElement;

    @Query('[js-hook-navigation-mobile]') menuMobile: HTMLElement;
    @Query('[js-hook-navigation-mobile-close-button]') closeButtonMobile: HTMLElement;

    @Query('[js-hook-search-modal]') search: HTMLElement;
    @Query('[js-hook-search-modal-toggle]') searchToggle: HTMLElement;
    @Query('[js-hook-search-modal-input]') searchInput: HTMLElement;

    @Listen('click', { target: 'closeButton' })
    @Listen('click', { target: 'closeButtonMobile' })
    @Listen('click', { target: 'menuSecondaryBackground' })
    closeMenu() {
        document.documentElement.classList.remove('no--scroll');
        this.menuSecondary.classList.remove(this.isOpenClass);
        this.menuMobile.classList.remove(this.isOpenClassMobile);
    }

    @Listen('click', { target: 'menuToggle' })
    openMenu() {
        document.documentElement.classList.add('no--scroll');
        this.menuSecondary.classList.add(this.isOpenClass);
        this.menuMobile.classList.add(this.isOpenClassMobile);
    }

    @Listen('click', { target: 'searchModalBackground' })
    closeSearchModal() {
        this.classList.remove(this.searchIsOpenClass);
    }

    @Listen('click', { target: 'searchToggle' })
    openSearch() {
        this.classList.add(this.searchIsOpenClass);

        once(this, 'transitionend', () => {
            this.searchInput.focus();
        });
    }
}
