import { h } from '@atomify/jsx';

const breakpoints: string[] = ['xs', 'sm', 'md', 'lg', 'xlg'];

const createClasses = (prefix: string, cols: (number | undefined)[]) => {
    return cols.reduce((classes: string[], col, i) => {
        classes.push(i === 0 ? `${prefix}-${col}` : `${prefix}-${col}--${breakpoints[i]}`);

        return classes;
    }, []);
};

interface ColInterface {
    extraClass?: string;
    children?: NodeList;
    cols?: (number | undefined)[];
    push?: (number | undefined)[];
    pull?: (number | undefined)[];
    pre?: (number | undefined)[];
    post?: (number | undefined)[];
}

export const Col = ({
    extraClass = '',
    children,
    cols = [],
    push = [],
    pull = [],
    pre = [],
    post = [],
}: ColInterface) => {
    const classes: string = [
        ...createClasses('o-col', cols),
        ...createClasses('u-push', push),
        ...createClasses('u-pull', pull),
        ...createClasses('u-pre', pre),
        ...createClasses('u-post', post),
    ].join(' ');

    return <div class={`${classes} ${extraClass}`}>{children}</div>;
};
